import {environment} from '@env/environment';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import en from '@assets/i18n/en.json';
import bn from '@assets/i18n/bn.json';
import {keyLanguage} from '../local-provider';

@Injectable({providedIn: 'root'})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    this.supportedLanguages = environment.supportedLanguages ?? ['en'];
    translateService.setTranslation('English', en);
    translateService.setTranslation('Bangla', bn);
  }

  get language(): string {
    return this.translateService.currentLang;
  }

  set language(language: string) {
    language = language || localStorage.getItem(keyLanguage) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    this.translateService.use(language);
  }

  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = '';

    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(keyLanguage, event.lang);
    });
  }

  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
