import {ExtraInfo} from '.';

export interface Report {
  id: number;
  title: string;
  description?: string;
  category: string;
  extraInfo: ExtraInfo;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreatedReport {
  id: number;
  description: string;
  questionId: number;
  remark: string;
  reportId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ReportPayload {
  type: ReportType;
  reportId: number;
  questionId: number;
  description?: string;
}

export enum ReportType {
  subjectQuestion = 'subjectQuestion',
  questionBankQuestion = 'questionBankQuestion',
  modelTestQuestion = 'modelTestQuestion',
  premiumModelTestQuestion = 'premiumModelTestQuestion'
}
