export * from './local-data.interface';
export * from './user.interface';
export * from './api.interface';
export * from './dashboard-item.interface';
export * from './model-test.interface';
export * from './subject.interface';
export * from './premium-plan.interface';
export * from './my-premium-plan.interface';
export * from './premium-plan-review.interface';
export * from './topic.interface';
export * from './question-bank.interface';
export * from './question-filter.interface';
export * from './model-test.interface';
export * from './model-test-submission.interface';
export * from './premium-model-test-submission.interface';
export * from './bookmark.interface';
export * from './premium-model-test.interface';
export * from './test-log.interface';
export * from './question.interface';
export * from './leader-board.interface';
export * from './content.interface';
export * from './course.interface';
export * from './report.interface';
export * from './payment.interface';
export * from './purchase-log.interface';
export * from './landing.interface'
export * from './auto-gen-exam.interface'
