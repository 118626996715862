import {CourseItem, DataStatus, ExtraInfo, OrderStatus, PremiumPlanItem} from '.';

export interface MyPremiumPlanItem {
  id: number;
  premiumPlan: PremiumPlanItem;
  price: number;
  orderStatus: OrderStatus;
  validUntil: Date;
  createdAt: Date;
  updatedAt: Date;
  isValid: boolean;
  nextExtensionUntil: Date;
}

export interface PremiumPlanPurchaseLog {
  id: number;
  validUntil: Date;
  transactionRef: string;
  transactionMehod: string;
  extraInfo: {purchaseType: string};
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  status: DataStatus;
  premiumPlanId: number;
  premiumPlan: PremiumPlanItem;
  isValid?: boolean;
  _count?: {paymentDetails: number};
}

export interface PremiumPlanAutoItem {
  id: number;
  title: string;
  status: string;
  type: string;
  expirationInMonths: number;
  extensionInMonths: number;
  price: number;
  extensionPrice: number;
  extraInfo: Pick<ExtraInfo, 'info' | 'images' | 'tags' | 'discount' | 'color' | 'freeAttempt'>;
  course: CourseItem;
  premiumPlanPurchaseLogs: any[]; // TODO
  createdAt: string;
  updatedAt: string;
  _count: Count;
  isValid?: boolean;
}

export interface Count {
  premiumGeneratedModelTestLogs: number;
}
