import {ExtraInfo} from '.';

export interface CourseItem {
  id: number;
  title: string;
  extraInfo?: ExtraInfo;
  createdAt?: Date;
  updatedAt?: Date;
  _count?: CourseItemCount;
}

export interface CourseItemCount {
  subjects?: number;
  questionBanks?: number;
  modelTests?: number;
  premiumPlans?: number;
  contents?: number;
}
