import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {ApiResponse, AppConfig, SubNotifier, Tokens, UserCredentials, UserRole} from '@core/interfaces';
import {
  apiGetUser,
  deactivateAccount,
  forgotPassword,
  forgotResetPassword,
  handleError,
  headerOptions,
  refresh,
  sendEmailVarification,
  resetPassword,
  signIn,
  signUp,
  updateUserPicture,
  verifyEmail,
  signupinFirebase,
  appConfig
} from '@core/api';
import {retry} from 'rxjs/internal/operators/retry';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {keySignedInOnce, keyUserCredentials} from '@core/local-provider';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {Capacitor} from '@capacitor/core';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  notify$ = new BehaviorSubject<any>('');
  userLiveData$ = new BehaviorSubject<UserCredentials | null>(null);
  private userCredential: UserCredentials | null = null;
  appConfigs$: BehaviorSubject<AppConfig[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient, public ngFireAuth: AngularFireAuth) {
    if (!Capacitor.isNativePlatform()) {
      GoogleAuth.initialize({
        scopes: ['profile', 'email'],
        clientId: environment.firebaseServerClientId,
        grantOfflineAccess: true
      });
    }

    this.userCredential = localStorage.getItem(keyUserCredentials)
      ? JSON.parse(localStorage.getItem(keyUserCredentials))
      : sessionStorage.getItem(keyUserCredentials)
      ? JSON.parse(sessionStorage.getItem(keyUserCredentials))
      : null;
  }

  // Gets the user credentials
  get getCredential(): UserCredentials | null {
    return this.userCredential;
  }

  get testerAccess(): boolean {
    return [UserRole.superAdmin, UserRole.admin, UserRole.tester].includes(this.userCredential?.role);
  }

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  async getAppConfig() {
    try {
      const response: ApiResponse<AppConfig[]> = await firstValueFrom(
        this.http.get<ApiResponse<AppConfig[]>>(appConfig, headerOptions).pipe(catchError(handleError))
      );
      this.appConfigs$.next(response.data);
    } catch (error) {
      // Handle error
    }
  }

  localSignUp(param: Pick<UserCredentials, 'name' | 'email' | 'password'>) {
    return this.http.post<ApiResponse<UserCredentials>>(signUp, param, headerOptions).pipe(catchError(handleError));
  }

  localSignIn(param: Pick<UserCredentials, 'email' | 'password'>) {
    return this.http
      .post<ApiResponse<UserCredentials>>(signIn, param, headerOptions)
      .pipe(retry(1), catchError(handleError));
  }

  firebaseSignUpIn(param: Pick<UserCredentials, 'name' | 'email' | 'firebaseId' | 'profileImage' | 'mobile' | 'role'>) {
    return this.http
      .post<ApiResponse<UserCredentials>>(signupinFirebase, param, headerOptions)
      .pipe(retry(1), catchError(handleError));
  }

  async firbaseProviderAuthLogin() {
    try {
      const googleUser = await GoogleAuth.signIn();
      if (googleUser.id) {
        return {
          email: googleUser.email,
          displayName: googleUser.name ?? googleUser['displayName'] ?? `${googleUser.givenName} ${googleUser.name}`,
          photoURL: googleUser.imageUrl,
          uid: googleUser.id,
          phoneNumber: null
        };
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  refreshToken() {
    const params = {userId: this.userCredential.id, refreshToken: this.userCredential.refreshToken};
    return this.http.post<ApiResponse<Tokens>>(refresh, params);
  }

  // FORGOT PASSWORD
  forgotPassword(params: Pick<UserCredentials, 'email'>) {
    return this.http.post<ApiResponse<any>>(forgotPassword, params).pipe(catchError(handleError));
  }

  resetForgottenPassword(params: {email: string; newPassword: string}) {
    return this.http.post<ApiResponse<any>>(forgotResetPassword, params).pipe(catchError(handleError));
  }

  varifyEmail(params: {email: string; emailVerification: string}) {
    return this.http.post<ApiResponse<any>>(verifyEmail, params).pipe(catchError(handleError));
  }

  //TODO return type
  sendEmailVerification(param: {email: string; info?: string}) {
    return this.http.post<ApiResponse<any>>(sendEmailVarification, param).pipe(catchError(handleError));
  }

  getUserInfo() {
    return this.http.get<ApiResponse<Omit<UserCredentials, 'password'>>>(apiGetUser).pipe(catchError(handleError));
  }

  //TODO return type
  resetPassword(param: {newPassword: string; oldPassword: string}) {
    return this.http.post<ApiResponse<any>>(resetPassword, param).pipe(catchError(handleError));
  }

  //TODO return type
  changePicture(param: {profileImage: string}) {
    return this.http.post<ApiResponse<any>>(updateUserPicture, param).pipe(catchError(handleError));
  }

  //TODO return type
  deactivateAccount() {
    return this.http.delete<ApiResponse<any>>(deactivateAccount).pipe(catchError(handleError));
  }

  // LOGOUT
  async logOutSetCredential() {
    this.userCredential = null;
    localStorage.removeItem(keyUserCredentials);
    sessionStorage.removeItem(keyUserCredentials);
    if (this.userCredential?.firebaseId) {
      await this.ngFireAuth.signOut();
    }
  }

  //  Checks is the user is authenticated.
  isAuthenticated(): boolean {
    return this.userCredential ? true : false;
  }

  // Sets the user credentials.
  setUserCredential(cred?: UserCredentials, rememberMe?: boolean) {
    this.userCredential = cred || null;
    const srorage = rememberMe ? localStorage : sessionStorage;

    if (cred) {
      cred.rememberMe = rememberMe ?? false;
      this.userCredential = cred;
      srorage.setItem(keyUserCredentials, JSON.stringify(cred));
    } else {
      srorage.removeItem(keyUserCredentials);
    }

    localStorage.setItem(keySignedInOnce, 'true');
  }
}
