import {Injectable} from '@angular/core';
import {ApiResponse, CourseItem, SubNotifier, UserCredentials} from '@core/interfaces';
import {handleError, updateCoursePreference, course} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {keyUserCredentials} from '../local-provider';

@Injectable({providedIn: 'root'})
export class CourseService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  get getLocalCoursePreference() {
    const userCredential: UserCredentials = JSON.parse(
      localStorage.getItem(keyUserCredentials) ?? sessionStorage.getItem(keyUserCredentials) ?? null
    );
    const data = userCredential.userCoursePreferences.map((item) => item.course);
    return data;
  }

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll() {
    return this.http.get<ApiResponse<CourseItem[]>>(course).pipe(catchError(handleError));
  }

  updatePreference(courseIds: number[]) {
    return this.http
      .post(updateCoursePreference, {
        courseIds
      })
      .pipe(catchError(handleError));
  }
}
