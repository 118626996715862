import {Injectable} from '@angular/core';
import {ApiResponse, QuestionBankQuestion, QuestionBankQuestionFilter, SubNotifier} from '@core/interfaces';
import {handleError, questionBankQuestion} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class QuestionBankQuestionService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll(filter: QuestionBankQuestionFilter) {
    let params = new HttpParams().set('questionBankId', filter.questionBankId);

    return this.http
      .get<ApiResponse<QuestionBankQuestion[]>>(questionBankQuestion, {
        params
      })
      .pipe(catchError(handleError));
  }
}
