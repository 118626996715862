import {Injectable, inject} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {FacadeService} from '../services';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  private facade = inject(FacadeService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data.roles && route.data.roles.indexOf(this.facade.auth.getCredential.role) === -1) {
      this.router.navigate(['/not-allowed/upcoming'], {
        queryParams: {redirect: state.url, pageTitle: route.data.pageTitle}
      });
      return false;
    } else if (route.data.status && route.data.status.indexOf(this.facade.auth.getCredential.status) === -1) {
      this.router.navigate(['/not-allowed/email'], {
        queryParams: {redirect: state.url, pageTitle: route.data.pageTitle}
      });
      return false;
    }
    return true;
  }
}

@Injectable({providedIn: 'root'})
export class OnboardGuard implements CanActivate {
  private facade = inject(FacadeService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.facade.setting.isOnboardingCompleted) {
      this.router.navigate(['/onboarding'], {
        queryParams: {redirect: state.url, pageTitle: route.data.pageTitle}
      });
      return false;
    }
    return true;
  }
}
