import {BookmarkType} from '.';

export interface SubjectQuestionFilter {
  search: string;
  subjectId: number;
  topicId: number;
}

export interface QuestionBankQuestionFilter {
  search: string;
  questionBankId: number;
}

export interface ModelTestQuestionFilter {
  modelTestId: number;
  search?: string;
  orderByTitle?: string;
}

export interface PremiumModelTestQuestionFilter {
  premiumModelTestId: number;
  search?: string;
  isReview?: boolean;
}

export interface BookmarkQuestionFilter {
  search: string;
  type: BookmarkType;
  orderById: string;
  courseIds: number[];
}
