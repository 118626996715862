import {CourseItem, SubjectTopic} from '.';

export interface SubjectItem {
  id: number;
  title: string;
  extraInfo?: any;
  createdAt?: Date;
  updatedAt?: Date;
  subjectTopics?: SubjectTopic[];
  course?: Pick<CourseItem, 'id' | 'title' | 'extraInfo'>;
  _count?: SubjectCount;
}

export interface SubjectCount {
  subjectQuestions?: number;
  subjectTopics?: number;
}

export interface SubjectFilter {
  search: string;
  orderByTitle: string;
  courseIds: number[]
}
