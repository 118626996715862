import Swiper from 'swiper';
import {ApiResponse, DataState, LCourse, Message} from '@core/interfaces';
import {assetLogo, defaultDataState, facebookIcon, playStoreLogo} from '@core/local-provider';
import {BehaviorSubject, map} from 'rxjs';
import {CommonModule} from '@angular/common';
import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {DirectiveModule} from '@core/directives/directive.module';
import {FacadeService} from '@core/services';
import {FooterComponent} from '@app/layouts/footer/fotter.component';
import {IonicModule} from '@ionic/angular';
import {NavbarComponent} from '../../layouts/navbar/navbar.component';
import {onItemEntry} from '@assets/animations/animations';
import {SwiperModule} from 'swiper/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
  animations: [onItemEntry],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, DirectiveModule, NavbarComponent, SwiperModule, FooterComponent]
})
export class LandingPage implements OnInit {
  @ViewChild('pageTop', {read: ElementRef}) pageTop: ElementRef;
  protected facade = inject(FacadeService);

  window = window;
  showScrollTop = false;
  swiper: Swiper;
  assetLogo = assetLogo;
  playStoreLogo = playStoreLogo;
  facebookIcon = facebookIcon;
  dataState$: BehaviorSubject<DataState> = new BehaviorSubject(defaultDataState);
  url =
    'https://play.google.com/store/apps/details?id=com.teamtryo.quizconsole&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

  slides = [
    {image: './../../../../assets/images/screenshot/dashboard'},
    {image: './../../../../assets/images/screenshot/subject'},
    {image: './../../../../assets/images/screenshot/question_bank'},
    {image: './../../../../assets/images/screenshot/exam'},
    {image: './../../../../assets/images/screenshot/exam_score'},
    {image: './../../../../assets/images/screenshot/exam_review'},
    {image: './../../../../assets/images/screenshot/profile'}
  ];

  processesData: ProcessedAnalytic[] = null;

  ngOnInit(): void {
    this.loadAnalytics();
  }

  loadAnalytics() {
    this.facade.analytics
      .getAllResources()
      .pipe(map(processData), untilDestroyed(this))
      .subscribe({
        next: (result: ProcessedAnalytic[]) => {
          this.processesData = result;
          this.dataState$.next({status: 'success'});
        },
        error: (error: ApiResponse<Message[]>) => this.dataState$.next(this.facade.ui.setError(error, 'l'))
      });
  }

  setSwiperInstance(swiper: Swiper) {
    this.swiper = swiper;
  }

  scrollUp(): void {
    this.pageTop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'}, 500);
  }
}

const processData = (data: ApiResponse<LCourse[]>) => {
  return data.data.map((course) => {
    const courseTitle = course.title;
    const image = course.extraInfo.images[0];
    const sumSubject = course.subjects.length;
    const sumSubQuestion = course.subjects.reduce((total, subject) => total + subject._count.subjectQuestions, 0);
    const sumModelTest = course.modelTests.length;
    const sumModelTestQuestion = course.modelTests.reduce(
      (total, modelTest) => total + modelTest._count.modelTestQuestions,
      0
    );
    const sumQuestionBank = course.questionBanks.length;
    const sumQuestionBankQuestion = course.questionBanks.reduce(
      (total, questionBank) => total + questionBank._count.questionBankQuestions,
      0
    );
    const sumPremiumModelTest = course.premiumPlans.reduce(
      (total, premiumPlan) => total + premiumPlan._count.premiumModelTests,
      0
    );
    const sumPremiumModelTestQuestion = course.premiumPlans.reduce(
      (total, premiumPlan) => total + premiumPlan._count.premiumModelTestQuestions,
      0
    );

    return {
      courseTitle,
      image,
      sumSubject,
      sumSubQuestion,
      sumModelTest,
      sumModelTestQuestion,
      sumQuestionBank,
      sumQuestionBankQuestion,
      sumPremiumModelTest,
      sumPremiumModelTestQuestion
    };
  });
};

type ProcessedAnalytic = {
  courseTitle: string;
  image: string;
  sumSubject: number;
  sumSubQuestion: number;
  sumModelTest: number;
  sumModelTestQuestion: number;
  sumQuestionBank: number;
  sumQuestionBankQuestion: number;
  sumPremiumModelTest: number;
  sumPremiumModelTestQuestion: number;
};
