import {Component, ElementRef, ViewChild} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {DirectiveModule} from '@core/directives/directive.module';
import {assetLogo} from '@core/local-provider';
import {onItemEntry} from '@assets/animations/animations';
import {CommonModule} from '@angular/common';
import { NavbarComponent } from '@app/layouts/navbar/navbar.component';

@Component({
  selector: 'app-about-us',
  templateUrl: 'about-us.component.html',
  styleUrls: ['about-us.component.scss'],
  animations: [onItemEntry],
  standalone: true,
  imports: [CommonModule, IonicModule, DirectiveModule, NavbarComponent]
})
export class AboutUsPage {
  @ViewChild('pageTop') pageTop: ElementRef;
  showScrollTop = false;
  assetLogo = assetLogo;
  constructor() {}

  scrollIntoView(elem: string) {
    const ref = this[elem];
    ref.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  logScrolling(event) {
    this.showScrollTop = event.detail?.scrollTop >= 150;
  }
}
