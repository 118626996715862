export const environment = {
  debug: true,
  defaultLanguage: 'English',
  doSpaces: {
    endpoint: 'sgp1.digitaloceanspaces.com', // note no https://
    region: 'sgp1',
    accessKeyId: 'DO009VE7L7MGC3B63DF7',
    secretAccessKey: 'CM3V2oCFg0nGefAlS+SZ4hmzlM29oRS4a9c54GhPjS0'
  },
  doSpacesUploadEndpoint: 'https://teamtryo-space.sgp1.digitaloceanspaces.com/',
  doSpacebucketName: 'teamtryo-space',
  firebaseConfig: {
    apiKey: 'AIzaSyAHyKHtzzRUEeHrrmjbAZ0I6VlEZo8db3M',
    authDomain: 'quizconsole-780df.firebaseapp.com',
    projectId: 'quizconsole-780df',
    storageBucket: 'quizconsole-780df.appspot.com',
    messagingSenderId: '592082803225',
    appId: '1:592082803225:web:1c11998e241adacd8be888',
    measurementId: 'G-7Y9K4465RD',
    databaseURL: 'https://quizconsole-780df-default-rtdb.asia-southeast1.firebasedatabase.app'
  },
  firebaseServerClientId: '592082803225-vorg6j9c6qfi5g3juthmed1neq17csjo.apps.googleusercontent.com',
  supportedLanguages: ['English', 'Bangla'],
  onesignalAppID: '76a6246a-3155-4f33-96d3-b5e5b4ac0988',
  production: false,
  // serverUrl: 'http://localhost:3000/api/',
  serverUrl: 'https://api.quizconsole.com/api/',
};
