import {CalendarBadge} from '@core/interfaces';

export class DummyData {
  public static calendarEvent = [
    {
      date: '2023-07-29',
      type: 'class',
      title: 'This is a class event',
      description: 'This is a class event description',
      color: this.getColor('class')
    },
    {
      date: '2023-07-29',
      type: 'exam',
      title: 'This is an exam event',
      description: 'This is a exam event description',
      color: this.getColor('exam')
    },
    {
      date: '2023-07-29',
      type: 'class',
      title: 'This is a class event',
      description: 'This is a class event description',
      color: this.getColor('class')
    },
    {
      date: '2023-07-29',
      type: 'metting',
      title: 'This is a metting event',
      description: 'This is a metting event description',
      color: this.getColor('metting')
    },
    {
      date: '2023-07-29',
      type: 'metting',
      title: 'This is a metting event',
      description: 'This is a metting event description',
      color: this.getColor('metting')
    },
    {
      date: '2023-07-28',
      type: 'notification',
      title: 'This is a notification event',
      description: 'This is a notification event description',
      color: this.getColor('notification')
    },
    {
      date: '2023-07-28',
      type: 'exam',
      title: 'This is an exam event',
      description: 'This is a exam event description',
      color: this.getColor('exam')
    },
    {
      date: '2022-02-05',
      colors: '',
      title: 'This is an uncategorized event',
      description: 'This is a exam event description',
      color: this.getColor('')
    }
  ];

  public static calendarBadge: CalendarBadge[] = [
    {
      date: '2023-07-30',
      colors: ['#4854e0'],
      count: 1
    },
    {
      date: '2023-07-29',
      colors: ['#ffc409', '#4854e0', '#42d77d', '#4854e0', '#42d77d'],
      count: 5
    },
    {
      date: '2023-07-28',
      colors: ['#42d77d', '#eb445a'],
      count: 2
    },
    {
      date: '2023-07-16',
      colors: [this.getColor('exam')],
      count: 1
    },
    {
      date: '2023-07-05',
      colors: ['#4854e0'],
      count: 1
    }
  ];

  public static getColor(type: string): string {
    return type === 'class' ? '#4854e0' : type === 'exam' ? '#ffc409' : type === 'metting' ? '#42d77d' : '#eb445a';
  }
}
