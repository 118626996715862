import {Component, NgZone, OnDestroy, inject} from '@angular/core';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';
import {AppConfig, ConfirmationData, ToastData} from '@core/interfaces';
import {environment} from '@env/environment';
import {fadeOut, slideInUp} from '@assets/animations/animations';
import {filter, timer} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {App, AppInfo, URLOpenListenerEvent} from '@capacitor/app';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Browser} from '@capacitor/browser';
import {urlPlayStoreQuizConsole} from './@core/local-provider';
import {FacadeService} from './@core/services';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [fadeOut, slideInUp]
})
export class AppComponent implements OnDestroy {
  facade = inject(FacadeService);

  showSplash = true;
  connected = true;
  showGlobalToastData = false;
  toastData: ToastData;

  constructor(
    private platform: Platform,
    private zone: NgZone,
    private router: Router,
    private tranS: TranslateService
  ) {
    this.initializeIonicApp();
    this.facade.auth.appConfigs$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((configs: AppConfig[]) => {
      const appVersion = configs.find((i) => i.title === 'AppVersion');
      if (this.facade.ui.isMobileApp) {
        App.getInfo().then((data: AppInfo) => {
          if (Number(data.build) < Number(appVersion.value)) {
            this.presentModal();
          }
        });
      }
    });
  }

  async presentModal() {
    const data: ConfirmationData = {
      header: this.tranS.instant('update.updateAvailable'),
      subHeader: this.tranS.instant('update.pleaseUpdate'),
      message: this.tranS.instant('update.description'),
      yesButton: this.tranS.instant('update.yesText'),
      yesCssClass: 'primary',
      hideCancel: true,
      backdropDismiss: false
    };
    this.facade.ui.showConfirm(data).then(async (result: boolean) => {
      if (result) {
        if (this.platform.is('android')) {
          await Browser.open({url: urlPlayStoreQuizConsole});
        }
      }
    });
  }

  ngOnDestroy() {
    this.facade.lang.destroy();
  }

  async initializeIonicApp() {
    this.deepLinking();
    this.initAppSettings();

    this.platform.ready().then(async () => {
      SplashScreen.hide();

      this.uiSubscriptions();

      this.facade.ui.setDeviceType(
        this.platform.is('mobile') && !this.platform.is('mobileweb'),
        this.platform.is('mobile'),
        this.platform.width(),
        this.platform.height()
      );

      this.facade.auth.getAppConfig();

      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        this.facade.oneSignal.oneSignalInit();
      }

      timer(1000)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.showSplash = false;
        });
    });
  }

  initAppSettings() {
    this.facade.setting.setTheme(this.facade.setting.currentTheme);
    this.facade.lang.init(environment.defaultLanguage, environment.supportedLanguages);
  }

  deepLinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'quizconsole.com';
        const appPath = event.url.split(domain).pop();

        if (appPath) {
          this.router.navigateByUrl(appPath);
          // do something
        }
      });
    });
  }

  uiSubscriptions() {
    // TOAST SUBSCRIPTIONS
    this.facade.ui.notification$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((data: ToastData) => {
        this.toastData = {...data, ...{show: true}};
        if (!data.show) {
          setTimeout(() => {
            if (this.toastData) {
              this.toastData.show = false;
            }
          }, data.duration ?? 3000);
        }
      });
  }

  dismissToast() {
    this.toastData = null;
  }
}
