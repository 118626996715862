import {Injectable} from '@angular/core';
import {
  ApiResponse,
  AutoGenExam,
  AutoGenFilter,
  PremiumGeneratedModelTestSubmissionItem,
  QuestionAnswer,
  SubNotifier,
  SubjectItem
} from '@core/interfaces';
import {handleError, premiumPlanAutoGenExam, premiumPlanAutoGenExamLog, premiumPlanAutoSelectList} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {keyExamWholeData} from '../local-provider';

@Injectable()
export class AutoPremiumExamService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAutoPlanSelect(courseId: number) {
    let params = new HttpParams().set('courseId', courseId);
    return this.http.get<ApiResponse<SubjectItem[]>>(premiumPlanAutoSelectList, {params}).pipe(catchError(handleError));
  }

  generateQuestions(filter: AutoGenFilter) {
    let params = new HttpParams()
      .set('premiumPlanId', filter.premiumPlanId)
      .set('subjectIds', JSON.stringify(filter.subjectIds))
      .set('countQuestion', filter.countQuestion);

    return this.http.get<ApiResponse<AutoGenExam>>(premiumPlanAutoGenExam, {params}).pipe(catchError(handleError));
  }

  submitModelTest(param: PremiumGeneratedModelTestSubmissionItem) {
    return this.http.post(premiumPlanAutoGenExamLog, param).pipe(catchError(handleError));
  }

  getWholeData(): AutoGenExam | null {
    return localStorage.getItem(keyExamWholeData) ? JSON.parse(localStorage.getItem(keyExamWholeData)) : null;
  }

  setWholeData(data: AutoGenExam) {
    return localStorage.setItem(keyExamWholeData, JSON.stringify(data));
  }

  removeWholeData() {
    return localStorage.removeItem(keyExamWholeData);
  }

  // time
  getTempAnswer(premiumGeneratedModelTestLogId: number): QuestionAnswer[] {
    const key = premiumGeneratedModelTestLogId + '_temp_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setTempAnswer(premiumGeneratedModelTestLogId: number, answer: QuestionAnswer[]): void {
    const key = premiumGeneratedModelTestLogId + '_temp_answer';
    localStorage.setItem(key, JSON.stringify(answer));
  }

  removeTempAnswers(pGMTLId: number) {
    const key = pGMTLId + '_temp_answer';
    localStorage.removeItem(key);
  }

  // temp answer
  getSubmissionItem(premiumGeneratedModelTestLogId: number): PremiumGeneratedModelTestSubmissionItem {
    const key = premiumGeneratedModelTestLogId + '_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setSubmissionItem(submissionItem: PremiumGeneratedModelTestSubmissionItem): void {
    const key = submissionItem.premiumGeneratedModelTestLogId + '_answer';
    localStorage.setItem(key, JSON.stringify(submissionItem));
  }

  removeSubmissionItem(premiumGeneratedModelTestLogId: number): void {
    const key = premiumGeneratedModelTestLogId + '_answer';
    localStorage.removeItem(key);
  }

  // timer
  getTimeCounter(premiumGeneratedModelTestLogId: number) {
    const key = premiumGeneratedModelTestLogId + '_timer';

    return JSON.parse(localStorage.getItem(key));
  }

  setTimeCounter(premiumGeneratedModelTestLogId: number, counter) {
    const key = premiumGeneratedModelTestLogId + '_timer';
    localStorage.setItem(key, JSON.stringify(counter));
    return JSON.parse(localStorage.getItem(key));
  }

  removeTimeCounter(premiumGeneratedModelTestLogId: number) {
    const key = premiumGeneratedModelTestLogId + '_timer';
    localStorage.removeItem(key);
  }
}
