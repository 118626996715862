export interface ApiResponse<D> {
  status: ErrorType;
  statusCode: number;
  message: Message[];
  data?: D;
  timestamp?: Date;
  method?: string;
  type?: ErrorType;
}

export interface Data<D, F> {
  data: D;
  pagination: Pagination<F>;
  // pagination: Pagination<F>;
}

export interface Pagination<F> {
  total: number;
  limit: number;
  page: number;
  pageCount: number;
  filter?: F;
}

export interface PaginationParam {
  limit?: number;
  page?: number;
  cursor?: string;
}

export enum ErrorType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
  validation = 'validation'
}

export interface Message {
  message: string;
  messageKey: string;
  property: string;
}

export interface ItemExtraInfo {
  info: string;
  images: string[];
  tags: string[];
  topics: string[];
}

export enum DataStatus {
  archived = 'archived',
  published = 'published',
  private = 'private'
}

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}
