import {ItemExtraInfo} from '.';

export interface PremiumModelTest {
  id: number;
  title: string;
  extraInfo?: ItemExtraInfo;
  type: 'flexible' | 'fixedDate';
  startDateTime: Date;
  endDateTime: Date;
  premiumPlanId: number;
  createdAt: Date;
  updatedAt: Date;
  timeLimitPerQuestion: number;
  _count?: PremiumModelTestCount;
}
export interface PremiumModelTestCount {
  premiumModelTestQuestions?: number;
  premiumModelTestLogs?: number;
}

export interface PremiumModelTestFilter {
  search: string;
  premiumPlanId: number;
  orderByTitle: string;
}
