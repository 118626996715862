export interface SubjectTopic {
  id: number;
  title: string;
  extraInfo?: any;
  subjectId: number;
  createdAt: Date;
  updatedAt: Date;
  _count?: TopicCount;
}

export interface TopicCount {
  subjectQuestions?: number;
}

export interface TopicFilter {
  subjectId: number;
  search: string;
  orderByTitle: string;
}
