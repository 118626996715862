import {CourseItem, ItemExtraInfo} from '.';

export interface ModelTest {
  id: number;
  title: string;
  extraInfo?: ItemExtraInfo;
  createdAt?: Date;
  updatedAt?: Date;
  course: Pick<CourseItem, 'id' | 'title' | 'extraInfo'>;
  _count?: ModelTestCount;
}

export interface ModelTestCount {
  modelTestQuestions: number;
}

export interface ModelTestFilter {
  search: string;
  orderByTitle: string;
  courseIds?: number[];
}
