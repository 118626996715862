import {CourseItem, ExtraInfo, PremiumModelTest, PremiumPlanPurchaseLog, PremiumPlanReview} from '.';

export interface PremiumPlanItem {
  id: number;
  title: string;
  price: number;
  extraInfo?: Pick<ExtraInfo, 'info' | 'tags' | 'images' | 'topics' | 'discount' | 'color'>;
  status: string;
  course: Pick<CourseItem, 'id' | 'extraInfo' | 'title'>;
  type: 'fixedDate' | 'flexible' | 'auto';
  extensionInMonths: number;
  maxExtensionCount: number;
  extensionPrice: number;
  courseId: number;
  startDateTime: Date;
  endDateTime: Date;
  expirationInMonths: number;
  validUntil: Date;
  createdAt: string;
  updatedAt: string;
  premiumPlanPurchaseLogs?: PremiumPlanPurchaseLog[];
  premiumModelTests?: PremiumModelTest[];
  premiumPlanReviews?: PremiumPlanReview[];
  wishCarts?: WishCart[];
  myPlanLog?: MyPlanLog; // FE derivative property
  _count?: PremiumPlanCount;
}

export interface MyPlanLog {
  isValid: boolean;
  validUntil: Date;
  nextExtensionUntil: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface PremiumPlanCount {
  premiumModelTests: number;
  premiumModelTestQuestions: number;
  premiumPlanPurchaseLogs: number;
  premiumPlanReviews: number;
}

export interface PremiumPlanFilter {
  search?: string;
  orderByTitle?: string;
  orderByPrice?: string;
  includePremiumModelTest?: boolean;
  orderStatus?: OrderStatus;
  courseIds?: number[];
}

export interface WishCartTransaction {
  orderStatus: OrderStatus;
  premiumPlanId: number;
  paymentStatus: PaymentStatus;
}

export interface PremiumPlanTransaction {
  transactionMehod?: string;
  transactionRef?: string;
  validUntil?: Date;
  paymentAmount?: number;
  paymentDate?: Date;
  updatedAt?: Date;
}

export interface WishCart {
  id: number;
  orderStatus: string;
  paymentStatus: PaymentStatus;
  premiumPlanId: number;
  userId: number;
  extraInfo?: any;
  createdAt: Date;
  updatedAt: Date;
}

export enum OrderStatus {
  cart = 'cart',
  wish = 'wish'
}

export enum PaymentStatus {
  paymentPending = 'paymentPending',
  paymentInProgress = 'paymentInProgress',
  paymentSuccessful = 'paymentSuccessful',
  paymentFailed = 'paymentFailed',
  paymentCancelled = 'paymentCancelled'
}

export interface CartPremiumPlanItem {
  id: number;
  orderStatus: OrderStatus;
  paymentStatus: PaymentStatus;
  purchasePrice: number;
  extraInfo?: any;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  premiumPlanId: number;
  premiumPlan: PremiumPlanItem;
  updatedAgo?: any; // FE derivative property
}

export interface MyWishCartPremiumPlanFilter {
  search: string;
  orderStatus: OrderStatus;
}
