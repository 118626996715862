import {Injectable} from '@angular/core';
import {ApiResponse, Data, PaginationParam, ContentFilter, Content, SubNotifier} from '@core/interfaces';
import {handleError, pagination, content} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ContentService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAllWithPagination(paginationParam: PaginationParam, filter: ContentFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('search', filter.search ?? '');

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }

    if (filter.orderById) {
      params = params.set('orderById', filter.orderById);
    }

    if (filter.contentType.length > 0) {
      params = params.set('contentType', JSON.stringify(filter.contentType));
    }

    return this.http
      .get<ApiResponse<Data<Content[], ContentFilter>>>(content + pagination, {
        params
      })
      .pipe(catchError(handleError));
  }

  findOne(id: number) {
    const params = new HttpParams().set('id', id);
    return this.http
      .get<ApiResponse<Content>>(content + '/' + id, {
        params
      })
      .pipe(catchError(handleError));
  }
}
