import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NetworkGuard, OnboardGuard} from '@core/guards';
import {NotFoundComponent} from './@shared/error-pages/not-found/not-found.component';
import {PrivacyPolicyPage} from './management/privacy-policy/privacy-policy.page';
import {TermsAndConditionsPage} from './management/terms-and-conditions/terms-and-conditions.page';
import {RefundPolicyPage} from './management/refund-policy/refund-policy.page';
import {AboutUsPage} from './management/about-us/about-us.component';
import {LandingPage} from './management/landing/landing.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [OnboardGuard, NetworkGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [NetworkGuard]
  },
  {
    path: 'landing',
    component: LandingPage
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingPageModule)
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsPage
  },
  {
    path: 'refund-policy',
    component: RefundPolicyPage
  },
  {
    path: 'about-us',
    component: AboutUsPage
  },
  {
    path: 'network-error',
    loadChildren: () => import('./network-error/network-error.module').then((m) => m.NetworkErrorPageModule)
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule {}
