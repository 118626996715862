import {QuestionAnswer} from './premium-model-test-submission.interface';
import {SubjectQuestion} from './question.interface';

export interface AutoGenExam {
  log: PremiumGeneratedModelTestLog;
  data: SubjectQuestion[];
}

export interface LogExtraInfo {
  subjectIds: number[];
  countQuestion: number;
}

export interface AutoGenFilter {
  search: string,
  readonly premiumPlanId: number;
  readonly subjectIds: number[];
  readonly countQuestion: number;
  running?: boolean;
}

export interface PremiumGeneratedModelTestSubmissionItem {
  premiumGeneratedModelTestLogId: number;
  answers: QuestionAnswer[] | null;
  startDateTime: Date;
  endDateTime: Date;
}

export interface PremiumGeneratedModelTestLog {
  id: number;
  title?: string;
  score?: number;
  correctAnswerCount?: number;
  incorrectAnswerCount?: number;
  unAnsweredCount?: number;
  answers?: QuestionAnswer[];
  questionIds: number[];
  extraInfo: any;
  premiumPlanId: number;
  startDateTime: Date;
  endDateTime: Date;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AutoGenLogFilter {
  search: string;
  premiumGeneratedModelTestLogId?: number;
}
