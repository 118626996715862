import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  ApiResponse,
  ConfirmationData,
  DataState,
  Message,
  NavData,
  TemplateData,
  ToastData,
  ToastType
} from '../interfaces';
import {
  assetAvatar,
  assetForbidden,
  assetInternalServerError,
  assetNotFound,
  assetSomethingWrong,
  assetTimeout,
  assetUnreachable,
  asssetImage
} from '../local-provider';

@Injectable({providedIn: 'root'})
export class UIService {
  public notification$: Subject<ToastData> = new Subject();
  public isMobileApp: boolean;
  public isMobile: boolean;
  public deviceWidth: number;
  public deviceHeight: number;
  public navSlice: number;
  public currentRoute$ = new BehaviorSubject<string>('');
  private navData = new BehaviorSubject<NavData>({
    pageTitle: '',
    showPageTitle: false
  });

  constructor(public alertController: AlertController) {}

  get getNavData$() {
    return this.navData.asObservable();
  }

  setNavData(data: NavData) {
    this.navData.next(data);
  }

  setDeviceType(isMobileApp: boolean, isMobile: boolean, deviceWidth: number, deviceHeight: number) {
    this.isMobileApp = isMobileApp;
    this.isMobile = isMobile;
    this.deviceWidth = deviceWidth;
    this.deviceHeight = deviceHeight;
    this.navSlice = deviceWidth < 400 ? 10 : deviceWidth < 500 ? 15 : deviceWidth < 600 ? 20 : 40;
  }

  processToast(title: string, message?: string, duration?: number) {
    this.notification$.next({
      show: true,
      title: title ?? 'Processing',
      message: message ?? 'Some data are being processed. Please wait...',
      icon: 'spinner',
      color: ToastType.primary,
      duration: duration ?? 300,
      dismissable: false
    });
  }

  errorToast(title: string, message?: string, duration?: number) {
    this.notification$.next({
      show: false,
      title: title ?? 'Failed',
      message: message ?? 'The action has been failed',
      icon: 'information-circle-outline',
      color: ToastType.error,
      duration: duration ?? 3000,
      dismissable: true
    });
  }

  successToast(title: string, message?: string, duration?: number) {
    this.notification$.next({
      show: false,
      title: title ?? 'Successful',
      message: message ?? 'The action has been completed successfully',
      icon: 'checkmark-circle',
      color: ToastType.primary,
      duration: duration ?? 3000,
      dismissable: true
    });
  }

  dismissToast() {
    this.notification$.next({
      show: false,
      title: '',
      message: '',
      icon: '',
      color: ToastType.primary,
      duration: 0,
      dismissable: null
    });
  }

  showConfirm(data: ConfirmationData): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let buttons = [
        {
          text: data.yesButton ?? 'Yes',
          cssClass: data.yesCssClass ?? 'danger',
          handler: (_) => resolve(true)
        },
        {
          text: data.noButton ?? 'No',
          cssClass: data.noCssClass ?? 'primary',
          handler: (_) => resolve(false)
        }
      ];

      if (data.hideCancel) {
        buttons.pop();
      }

      const confirm = await this.alertController.create({
        header: data.header,
        subHeader: data.subHeader,
        message: data.message,
        buttons,
        backdropDismiss: data.backdropDismiss ?? true
      });

      await confirm.present();
    });
  }

  setError(
    error: ApiResponse<Message[]>,
    page:
      | 's'
      | 'st'
      | 'sq'
      | 'mt'
      | 'mtl'
      | 'qb'
      | 'pp'
      | 'mpp'
      | 'mpmt'
      | 'mpmtr'
      | 'mpmtq'
      | 'mpmtl'
      | 'q'
      | 'mplb'
      | 'c'
      | 'crs'
      | 'mppcl'
      | 'mppwl'
      | 'da'
      | 'qr'
      | 'l'
      | string
  ): DataState {
    // 0 - could not reach server, 400 - bad request, 401 - Unauthorized, 403 -	Forbidden, 404 - Not found, 500 - internal server error, 501 - not implemented, 504 - Gateway timeout
    const message = {
      0: {
        icon: assetUnreachable,
        buttons: {retry: true}
      },
      400: {
        icon: assetSomethingWrong,
        buttons: {report: true}
      },
      401: {
        icon: assetForbidden,
        buttons: {back: true}
      },
      403: {
        icon: assetForbidden,
        buttons: {back: true}
      },
      404: {
        icon: assetNotFound,
        buttons: {back: true}
      },
      500: {
        icon: assetInternalServerError,
        buttons: {back: true, report: true}
      },
      501: {
        icon: assetNotFound,
        buttons: {back: true}
      },
      504: {
        icon: assetTimeout,
        buttons: {retry: true}
      }
    }[error?.statusCode] ?? {
      icon: assetSomethingWrong,
      buttons: {retry: true, report: true}
    };

    return {
      status: 'error',
      page,
      buttons: message.buttons,
      message: {
        title: `error.${error.statusCode}.title`,
        subtitle: `error.${error.statusCode}.subtitle`,
        icon: message.icon
      },
      error
    };
  }

  setNoData(data: TemplateData): DataState {
    const message = {
      s: {
        icon: 'albums-outline',
        subtitle: data.keyword ? `subject.noDataSubtitleSearch` : 'subject.noDataSubtitle'
      },
      st: {
        icon: 'list-outline',
        subtitle: data.keyword ? `subject.topic.noDataSubtitleSearch` : 'subject.topic.noDataSubtitle'
      },
      sq: {
        icon: 'list-outline',
        subtitle: data.keyword ? `question.noDataSubtitleSearch` : 'question.noDataSubtitle'
      },
      mt: {
        icon: 'alarm-outline',
        subtitle: data.keyword ? `modelTest.noDataSubtitleSearch` : 'modelTest.noDataSubtitle'
      },
      mtr: {
        icon: 'alarm-outline',
        subtitle: data.keyword ? `modelTestReview.noDataSubtitleSearch` : 'modelTestReview.noDataSubtitle'
      },
      mtl: {
        icon: 'analytics-outline',
        subtitle: data.keyword ? `testLog.modelTestLog.noDataSubtitleSearch` : 'testLog.modelTestLog.noDataSubtitle'
      },
      qb: {
        icon: 'layers-outline',
        title: 'No Data',
        subtitle: data.keyword ? `questionBank.noDataSubtitleSearch` : 'questionBank.noDataSubtitle'
      },
      pp: {
        icon: 'ribbon-outline',
        subtitle: data.keyword ? `premiumPlan.noDataSubtitleSearch` : 'premiumPlan.noDataSubtitle',
        noDtails: `premiumPlan.noDtails`
      },
      ppd: {
        icon: 'ribbon-outline',
        subtitle: `premiumPlanDetails.noDataSubtitle`
      },
      ppdr: {
        icon: 'ribbon-outline',
        subtitle: `premiumPlanDetails.noDataSubtitle`
      },
      mpp: {
        icon: 'cube-outline',
        subtitle: data.keyword ? `premiumPlan.noDataSubtitleSearch` : 'premiumPlan.noDataSubtitle'
      },
      mpmt: {
        icon: 'alarm-outline',
        subtitle: data.keyword ? `myPremiumModelTest.noDataSubtitleSearch` : 'myPremiumModelTest.noDataSubtitle'
      },
      mpmtr: {
        icon: 'alarm-outline',
        subtitle: data.keyword ? `modelTestReview.noDataSubtitleSearch` : 'modelTestReview.noDataSubtitle'
      },
      mpmtq: {
        icon: 'alarm-outline',
        subtitle: data.keyword
          ? `myPremiumModelTestQuestion.noDataSubtitleSearch`
          : 'myPremiumModelTestQuestion.noDataSubtitle'
      },
      mpmtl: {
        icon: 'analytics-outline',
        subtitle: data.keyword
          ? `testLog.myPremiumModelTestLog.noDataSubtitleSearch`
          : 'testLog.myPremiumModelTestLog.noDataSubtitle'
      },
      mplb: {
        icon: 'people-outline',
        subtitle:
          data.status === 'noPlanPurchased'
            ? 'leaderBoard.premiumLeaderBoard.noPlanPurchasedSubtitle'
            : data.keyword
            ? `leaderBoard.premiumLeaderBoard.noDataSubtitleSearch`
            : 'leaderBoard.premiumLeaderBoard.noDataSubtitle'
      },
      pl: {
        icon: 'stopwatch-outline',
        subtitle: data.keyword ? `history.noDataSubtitleSearch` : 'history.noDataSubtitle'
      },
      q: {
        icon: 'copy-outline',
        subtitle: data.keyword ? `question.noDataSubtitleSearch` : 'question.noDataSubtitle'
      },
      b: {
        icon: 'bookmark-outline',
        subtitle: data.keyword ? `question.noDataSubtitleSearch` : 'question.noDataSubtitle'
      },
      c: {
        icon: 'document-text-outline',
        subtitle: data.keyword ? `content.noDataSubtitleSearch` : 'content.noDataSubtitle'
      },
      crs: {
        icon: 'folder-outline',
        subtitle: 'course.noDataSubtitle'
      },
      mppwl: {
        icon: 'heart-outline',
        subtitle: data.keyword ? `mpremWishLog.noDataSubtitleSearch` : 'mpremWishLog.noDataSubtitle'
      },
      mppcl: {
        icon: 'cart-outline',
        subtitle: data.keyword ? `mpremCartLog.noDataSubtitleSearch` : 'mpremCartLog.noDataSubtitle'
      },
      n: {
        icon: 'notifications-outline',
        title: 'notification.title',
        subtitle: 'notification.noDataSubtitle'
      }
    }[data.page];

    return {
      status: 'noData',
      page: data.page,
      buttons: {
        search: data.keyword?.length > 0
      },
      message: {
        keyword: data.keyword,
        title: message?.title ?? 'common.noData',
        subtitle: message.subtitle,
        icon: message.icon
      }
    };
  }

  onImgError(event: any, imageType: string) {
    event.target.src = imageType === 'avatar' ? assetAvatar : asssetImage;
    return event.target.src;
  }
}
