import {Injectable} from '@angular/core';
import {
  ApiResponse,
  PremiumModelTestQuestion,
  PremiumModelTestQuestionFilter,
  PremiumModelTestSubmissionItem,
  QuestionAnswer,
  SubNotifier
} from '@core/interfaces';
import {handleError, premiumModelTestQuestion, premiumModelTestLog} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PremiumModelTestQuestionService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll(filter: PremiumModelTestQuestionFilter) {
    let params = new HttpParams().set('premiumModelTestId', filter.premiumModelTestId);

    if (filter.isReview) {
      params = params.set('isReview', filter.isReview);
    }

    return this.http
      .get<ApiResponse<PremiumModelTestQuestion[]>>(premiumModelTestQuestion, {params})
      .pipe(catchError(handleError));
  }

  submitModelTest(param: PremiumModelTestSubmissionItem) {
    return this.http.post(premiumModelTestLog, param).pipe(catchError(handleError));
  }

  // time
  getTempAnswer(premiumModelTestId: number): QuestionAnswer[] {
    const key = premiumModelTestId + '_temp_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setTempAnswer(premiumModelTestId: number, answer: QuestionAnswer[]): void {
    const key = premiumModelTestId + '_temp_answer';
    localStorage.setItem(key, JSON.stringify(answer));
  }

  removeTempAnswers(premiumModelTestId: number) {
    const key = premiumModelTestId + '_temp_answer';
    localStorage.removeItem(key);
  }

  // temp answer
  getSubmissionItem(premiumModelTestId: number): PremiumModelTestSubmissionItem {
    const key = premiumModelTestId + '_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setSubmissionItem(submissionItem: PremiumModelTestSubmissionItem): void {
    const key = submissionItem.premiumModelTestId + '_answer';
    localStorage.setItem(key, JSON.stringify(submissionItem));
  }

  removeSubmissionItem(premiumModelTestId: number): void {
    const key = premiumModelTestId + '_answer';
    localStorage.removeItem(key);
  }

  // timer
  getTimeCounter(premiumModelTestId: number) {
    const key = premiumModelTestId + '_timer';

    return JSON.parse(localStorage.getItem(key));
  }

  setTimeCounter(premiumModelTestId: number, counter) {
    const key = premiumModelTestId + '_timer';
    localStorage.setItem(key, JSON.stringify(counter));
    return JSON.parse(localStorage.getItem(key));
  }

  removeTimeCounter(premiumModelTestId: number) {
    const key = premiumModelTestId + '_timer';
    localStorage.removeItem(key);
  }
}
