import {AppPageItem} from '@core/interfaces';

export const appPages: AppPageItem[] = [
  {title: 'sideBar.dashboard', url: '/dashboard', icon: 'podium-outline'},
  // { title: 'Schedule', url: '/schedule', icon: 'calendar-outline' },

  {title: 'sideBar.subject', url: '/subjects', icon: 'albums-outline'},
  {title: 'sideBar.questionBank', url: '/question-bank', icon: 'layers-outline'},
  {title: 'sideBar.modelTest', url: '/model-tests', icon: 'alarm-outline'},
  {title: 'sideBar.bookmarks', url: '/bookmarks', icon: 'bookmark-outline'},
  {title: 'sideBar.contents', url: '/contents', icon: 'document-outline'},

  {title: 'sideBar.premiumPlans', url: '/premium-plans', icon: 'ribbon-outline'},
  {title: 'sideBar.myPremiumPlan', url: '/my-premium-plans', icon: 'cube-outline'},
  {title: 'sideBar.leaderBoard', url: '/leader-board', icon: 'people-outline'},
  {title: 'sideBar.testLog', url: '/test-logs', icon: 'analytics'},
  {title: 'sideBar.history', url: '/history', icon: 'stopwatch-outline'}
];

export const upComingFeaturesRoutes = [
  // '/premium-plans',
  // '/my-premium-plans',
  // '/leader-board',
  // '/contents',
  // '/wish-cart'
];
export const inactiveAllowedRoutes = ['/profile', '/not-allowed', '/course-preference'];
