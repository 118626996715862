import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';
import {ThemeLangSettingsComponent} from './theme-lang-settings.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
  declarations: [ThemeLangSettingsComponent],
  exports: [ThemeLangSettingsComponent]
})
export class ThemeLangSettingsModule {}
