import {
  addToCartOrWishList,
  buyPremiumPlan,
  handleError,
  pagination,
  premiumPlan,
  premiumPlanAutoPlanList,
  premiumPlanMyPlanList,
  premiumPlanMyWishCartList,
  premiumPlanReviews,
  removeFromCartOrWishList,
  updateCartList
} from '@core/api';
import {
  ApiResponse,
  PremiumPlanAutoItem,
  CartPremiumPlanItem,
  Data,
  MyPremiumPlanItem,
  MyWishCartPremiumPlanFilter,
  PaginationParam,
  PremiumPlanFilter,
  PremiumPlanItem,
  PremiumPlanReview,
  PremiumPlanReviewFilter,
  SubNotifier,
  WishCartTransaction
} from '@core/interfaces';
import {BehaviorSubject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class PremiumPlanService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAllWithPagination(paginationParam: PaginationParam, filter: PremiumPlanFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('search', filter.search ?? '');

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }
    if (filter.orderByPrice) {
      params = params.set('orderByPrice', filter.orderByPrice);
    }
    if (filter.orderByTitle) {
      params = params.set('orderByTitle', filter.orderByTitle);
    }

    return this.http
      .get<ApiResponse<Data<PremiumPlanItem[], PremiumPlanFilter>>>(premiumPlan + pagination, {params})
      .pipe(catchError(handleError));
  }

  getAutoPremiumPlans() {
    return this.http.get<ApiResponse<PremiumPlanAutoItem[]>>(premiumPlanAutoPlanList).pipe(catchError(handleError));
  }

  getMyPremiumPlans(filter: PremiumPlanFilter) {
    let params;
    if (filter.orderByTitle) {
      params = new HttpParams().set('orderByTitle', filter.orderByTitle);
    }

    return this.http
      .get<ApiResponse<MyPremiumPlanItem[]>>(premiumPlanMyPlanList, {params})
      .pipe(catchError(handleError));
  }

  getMyWishCart(filter: MyWishCartPremiumPlanFilter) {
    const params = new HttpParams().set('orderStatus', JSON.stringify([filter.orderStatus]));
    return this.http
      .get<ApiResponse<CartPremiumPlanItem[]>>(premiumPlanMyWishCartList, {params})
      .pipe(catchError(handleError));
  }

  getOne(filter: PremiumPlanFilter, id: number | string) {
    let params = new HttpParams();

    if (filter.includePremiumModelTest) {
      params = params.set('includePremiumModelTest', filter.includePremiumModelTest);
    }

    return this.http
      .get<ApiResponse<PremiumPlanItem>>(premiumPlan + '/' + id, {
        params
      })
      .pipe(catchError(handleError));
  }

  getReviews(paginationParam: PaginationParam, filter: PremiumPlanReviewFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('premiumPlanId', filter.premiumPlanId);

    if (filter.includeReplies) {
      params = params.set('includeReplies', filter.includeReplies);
    }

    return this.http
      .get<ApiResponse<Data<PremiumPlanReview, PremiumPlanReviewFilter>>>(premiumPlanReviews, {
        params
      })
      .pipe(catchError(handleError));
  }

  addToCartOrWishList(body: WishCartTransaction) {
    return this.http.post<ApiResponse<any>>(addToCartOrWishList, body).pipe(catchError(handleError));
  }

  updateCartItem(body: WishCartTransaction) {
    return this.http.patch<ApiResponse<any>>(updateCartList, body).pipe(catchError(handleError));
  }

  removeCartOrWishList(premiumPlanId: number) {
    const params = new HttpParams().set('premiumPlanId', premiumPlanId);
    return this.http.delete<ApiResponse<any>>(removeFromCartOrWishList, {params}).pipe(catchError(handleError));
  }

  buyPremiumPlans(body: {wishCartIds: number[]; totalPrice: number}) {
    return this.http.post<ApiResponse<any>>(buyPremiumPlan, body).pipe(catchError(handleError));
  }
}
