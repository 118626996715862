import {SafeUrl} from '@angular/platform-browser';
import {ApiResponse, Message} from './api.interface';

export interface DataState {
  status: 'loading' | 'error' | 'noData' | 'success';
  page?: string;
  buttons?: {
    search?: boolean;
    report?: boolean;
    retry?: boolean;
    back?: boolean;
  };
  message?: {
    keyword?: string;
    title?: string;
    subtitle?: string;
    icon?: string;
  };
  error?: ApiResponse<Message[]>;
}

export interface TemplateData {
  status: string;
  page:
    | 's'
    | 'st'
    | 'sq'
    | 'mt'
    | 'mtl'
    | 'qb'
    | 'pp'
    | 'ppd'
    | 'ppdr'
    | 'mpp'
    | 'mpmt'
    | 'mpmtr'
    | 'mpmtq'
    | 'mpmtl'
    | 'q'
    | 'mplb'
    | 'c'
    | 'b'
    | 'crs'
    | 'mppcl'
    | 'mppwl'
    | 'pl'
    | 'n';
  keyword?: string;
  isSearching?: boolean;
  message?: string;
  image?: string;
}

export interface Language {
  code: string;
  title: string;
  message: string;
  messageTitle: string;
  messageSubTitle: string;
  disabled: boolean;
}

export interface NavData {
  pageTitle: string;
  showPageTitle: boolean;
}

export interface AppPageItem {
  title?: string;
  subtitle?: string;
  icon?: string;
  url?: string;
  children?: [];
  isPremium?: boolean;
}

export interface ToastData {
  show: boolean;
  title: string;
  message: string;
  icon?: string;
  color: ToastType;
  duration?: number;
  dismissable?: boolean;
}

export enum ToastType {
  success = 'success',
  error = 'danger',
  warning = 'warning',
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary'
}

export interface FileHandle {
  file?: File;
  fileName?: string;
  url?: SafeUrl;
  uploadUrl?: string;
  progress?: number;
  uploading?: boolean;
}

export interface CalendarBadge {
  date: string;
  colors: string[];
  count: number;
}

export interface ConfirmationData {
  header: string;
  subHeader: string;
  message: string;
  yesCssClass?: string;
  noCssClass?: string;
  yesButton?: string
  noButton?: string;
  hideCancel? : boolean;
  backdropDismiss?: boolean 
}

export interface ValueTitle {
  value: any;
  title: string;
}

export enum BookmarkType {
  sq = 'sq',
  mtq = 'mtq',
  pmtq = 'pmtq',
  qbq = 'qbq'
}

export interface SubNotifier {
  refresh: boolean;
  actionType?: any;
}

export interface CustomToastData {
  color: string;
  icon: string;
  buttonIcon?: string;
  title: string;
  subtitle: string;
}

export interface PasswordStrength {
  digits: boolean;
  lower: boolean;
  upper: boolean;
  nonWords?: boolean;
  lengths: boolean;
}
