import {ExtraInfo} from './dashboard-item.interface';

export interface LCourse {
  id: number;
  title: string;
  extraInfo: ExtraInfo;
  subjects: LSubject[];
  questionBanks: LQuestionBank[];
  modelTests: LModelTest[];
  premiumPlans: LPremiumPlan[];
}

export interface LSubject {
  _count: {
    subjectQuestions: number;
  };
}

export interface LQuestionBank {
  _count: {
    questionBankQuestions: number;
  };
}

export interface LModelTest {
  _count: {
    modelTestQuestions: number;
  };
}

export interface LPremiumPlan {
  _count: {
    premiumModelTests: number;
    premiumModelTestQuestions: number;
  };
}
