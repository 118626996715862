import {Language} from '@core/interfaces';

const bangla: Language = {
  code: 'Bangla',
  title: 'বাংলা',
  messageTitle: 'শিক্ষা',
  messageSubTitle: 'এর গুরুত্ব',
  message: `দুর্বলতাটিকে শক্তিতে পরিণত করার মূল চাবিকাঠি শিক্ষা।
  এটি আমাদের সামনে থাকা সমস্যাগুলি বোঝার বিভিন্ন সরঞ্জাম এবং 
  উপায় সরবরাহ করে এবং সেগুলি সমাধানে সহায়তা করে। আরও গুরুত্বপূর্ণ, 
  শিক্ষার প্রয়োজনে যখন সঠিক সিদ্ধান্ত গ্রহণ এবং বসন্তকে কার্যকর করার জন্য 
  যথেষ্ট মানসিক তত্পরতা সরবরাহ করে। অনেক ধরণের গবেষণা দেখায় যে শিক্ষিত 
  মহিলারা তাদের সিদ্ধান্ত গ্রহণের ক্ষমতা উন্নত করার কারণে আরও সহজেই লিঙ্গ 
  পক্ষপাত এবং বৈবাহিক সহিংসতার বিরুদ্ধে দাঁড়াতে পারেন।`,
  disabled: false
};

const english: Language = {
  code: 'English',
  title: 'English',
  messageTitle: 'Education',
  messageSubTitle: 'Importance of It',
  message: `Education is the key to turn a weakness into a strength. 
    It offers different tools and ways to understand problems 
    that lay ahead of us and helps resolve them. More importantly, 
    education provides us with considerable mental agility to make 
    the right decisions and spring into action when needed. 
    Many types of research show that educated women can more easily stand up 
    against gender bias and marital violence as they have 
    improved their decision-making capabilities.`,
  disabled: false
};

export const allThemes: string[] = ['dark', 'light'];
export const supportedLanguages: Language[] = [bangla, english];
