import {CreatedReport} from '.';

export interface Question {
  id: number;
  question: string;
  answer: string;
  choices: Choice[];
  addedBy: number;
  extraInfo: QuestionExtraInfo;
  createdAt: string;
  updatedAt: string;
  providedAnswer?: string;
}

export type SubjectQuestion = Question & {topicId: number; subjectId: number; subjectQuestionReports?: CreatedReport[]};

export type QuestionBankQuestion = Question & {questionBankId: number; questionBankQuestionReport?: CreatedReport[]};

export type ModelTestQuestion = Question & {
  modelTestId: number;
  modelTestQuestionReport?: CreatedReport[];
};

export type PremiumModelTestQuestion = Question & {
  premiumModelTestId: number;
  premiumModelTestQuestionReport?: CreatedReport[];
};

export type BookmarkQuestion = Question & {
  topicId: number;
  subjectId: number;
  questionBankId: number;
  subjectQuestionReports?: CreatedReport[];
  modelTestQuestionReports?: CreatedReport[];
  questionBankQuestionReports?: CreatedReport[];
  premiumModelTestQuestionReports?: CreatedReport[];
};

export interface Choice {
  answer: string;
  answerKey: string;
}

export interface QuestionExtraInfo {
  tags: any[];
  difficulty: string;
  info: string;
}
