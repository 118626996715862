import {Injectable} from '@angular/core';
import {ApiResponse, ModelTest, SubNotifier} from '@core/interfaces';
import {handleError, modelTest} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ModelTestService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll() {
    return this.http.get<ApiResponse<ModelTest[]>>(modelTest).pipe(catchError(handleError));
  }
}
