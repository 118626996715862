import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import OneSignal from 'onesignal-cordova-plugin';
import {OpenedEvent} from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import {UserCredentials} from '../interfaces';

@Injectable({providedIn: 'root'})
export class OneSignalService {
  constructor(private router: Router) {}
  oneSignalInit() {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId(environment.onesignalAppID);

    OneSignal.setNotificationOpenedHandler(async (jsonData: OpenedEvent) => {
      const additionalData = jsonData.notification.additionalData as any;
      if ('route' in additionalData) {
        const queryParams = await this.getParams(additionalData);
        this.router.navigate([additionalData.route], {queryParams});
      }
    });

    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {});
  }

  async getParams(additionalData) {
    const queryParams = {};
    if ('params' in additionalData) {
      const params = JSON.parse(additionalData.params);
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          queryParams[key] = JSON.stringify(params[key]);
        }
      }
    }
    return queryParams;
  }

  setUserIdToOnsignal(user: UserCredentials) {
    OneSignal.setExternalUserId(user.id.toString());
  }
}
