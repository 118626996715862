import {DataState} from '../interfaces';

export const defaultDataState: DataState = {
  status: 'loading',
  message: {
    title: 'Loading Data',
    subtitle: 'Please wait while loading data'
  }
};

export const footerItems = [
  {
    title: 'Home',
    url: '/landing',
    icon: 'home-outline'
  },
  {
    title: 'About Us',
    url: '/about-us',
    icon: 'information-circle-outline'
  },
  {
    title: 'Terms & Conditions',
    url: '/terms-and-conditions',
    icon: 'document-text-outline'
  },
  {
    title: 'Refund Policy',
    url: '/refund-policy',
    icon: 'wallet-outline'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy-policy',
    icon: 'lock-closed-outline'
  }
];
