import {BookmarkType} from './local-data.interface';

export interface Bookmark {
  id: number;
  type: BookmarkType;
  questionIds: number[];
  userId: number;
  createdAt: Date;
  updateAt: Date;
}
