export interface ModelTestSubmissionItem {
  modelTestId: number;
  answers: QuestAnswer[];
  startDateTime: Date;
  endDateTime: Date;
}

export interface QuestAnswer {
  answer: string;
  id: number;
}

export interface Answer {
  id: number;
  annswer: string;
}

export interface ModelTestResultLog {
  id: number;
  userId: number;
  modelTestId: number;
  score: number;
  correctAnswerCount: number;
  incorrectAnswerCount: number;
  unAnsweredCount: number;
  answers: Answer[];
  startDateTime: Date;
  endDateTime: Date;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}
