import {UserCredentials} from './user.interface';

export interface LeaderBoard {
  id: number;
  premiumModelTestId: number;
  score: number;
  premiumModelTest?: {title?: string};
  answers: {id: number; answer?: string}[];
  correctAnswerCount: number;
  incorrectAnswerCount: number;
  unAnsweredCount: number;
  startDateTime: string;
  endDateTime: string;
  createdAt?: Date;
  updatedAt?: Date;
  user: UserCredentials;
  rank?: number;
}

export interface LeaderBoardFilter {
  search: string;
  date: string;
  premiumModelTestId: number;
}
