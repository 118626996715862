import {CourseItem, ItemExtraInfo} from '.';

export interface QuestionBank {
  id: number;
  title: string;
  extraInfo?: ItemExtraInfo;
  createdAt: Date;
  updatedAt: Date;
  courseId: number;
  course?: Pick<CourseItem, 'id' | 'title' | 'extraInfo'>;
  _count: QuestionBankCount;
}

export interface QuestionBankCount {
  questionBankQuestions: number;
}

export interface QuestionBankFilter {
  search: string;
  orderByTitle: string;
  courseIds: number[]
}
