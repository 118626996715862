// ------- PNGS
export const assetLogo = './../../../../assets/images/logo.png';
export const assetAvatar = './../../../../assets/images/avatar.png';
export const asssetImage = './../../../../assets/images/image.png';
export const asset404 = './../../../../assets/images/404.png';
export const assetNotFound = './../../../../assets/images/not-found.png';
export const assetForbidden = './../../../../assets/images/forbidden.png';
export const assetUnreachable = './../../../../assets/images/unreachable.png';
export const assetSomethingWrong = './../../../../assets/images/something-wrong.png';
export const assetMoveData = './../../../../assets/images/move-data.png';
export const assetTimeout = './../../../../assets/images/timeout.png';
export const assetInternalServerError = './../../../../assets/images/internal-server-error.png';
export const assetNoEvent = './../../../../assets/images/no-event.png'; //TODO REPLACE
export const assetNoInternet = './../../../../assets/images/no-internet.png';
export const assetQcBg = './../../../../assets/images/qc-bg.jpeg';
export const assetPaymentHorizontal = './../../../../assets/images/payment-banner-horizontal.jpg';
export const playStoreLogo = 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png';
export const facebookIcon = './../../../../assets/images/facebook.png';
export const iconQb = './../../../../assets/custom-icons/q.png';
export const iconSubject = './../../../../assets/custom-icons/s.png';
export const iconModelTest = './../../../../assets/custom-icons/m.png';
export const iconBookmark = './../../../../assets/custom-icons/b.png';
export const iconContent = './../../../../assets/custom-icons/c.png';
export const iconTestLog = './../../../../assets/custom-icons/t.png';

export const urlPlayStoreQuizConsole = 'https://play.google.com/store/apps/details?id=com.teamtryo.quizconsole';
export const urlFacebookGroup = 'https://www.facebook.com/groups/289729185357680';

export const logoOutline = './../../../../assets/svgs/logo-outline.svg';
