import {UserCredentials} from './user.interface';

export interface PremiumPlanReview {
  id: number;
  star: number;
  review: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  premiumPlanId: number;
  userId: number;
  user: Pick<UserCredentials, 'id' | 'email' | 'name' | 'profileImage'>;
  premiumPlanReplies: PremiumPlanReply[];
}
export interface PremiumPlanReply {
  id: number;
  reply: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  premiumPlanReviewId: number;
  user: Pick<UserCredentials, 'id' | 'email' | 'name' | 'profileImage'>;
}

// export interface PremiumPlanReview {
//   averageStars: number;
//   reviews: PremiumPlanReviewItem[];
// }

export interface PremiumPlanReviewFilter {
  orderById?: string;
  orderByStars?: string;
  premiumPlanId: number;
  includeReplies?: boolean;
}
