import {FacadeService} from '@core/services';
import {Injectable, inject} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private facade = inject(FacadeService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.facade.auth.getCredential?.accessToken) {
      const userToken = this.facade.auth.getCredential?.accessToken;
      const modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${userToken}`)
      });

      return next.handle(modifiedReq);
    }

    return next.handle(req);
  }
}
