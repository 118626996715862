import {Injectable} from '@angular/core';
import {ApiResponse, DashboardAPIResponse, LCourse, SubNotifier} from '@core/interfaces';
import {dashboard, handleError, resources} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AnalyticService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll() {
    return this.http.get<ApiResponse<DashboardAPIResponse>>(dashboard).pipe(catchError(handleError));
  }

  getAllResources() {
    return this.http.get<ApiResponse<LCourse[]>>(resources).pipe(catchError(handleError));
  }
}