import {Component, Input, inject} from '@angular/core';
import {FacadeService} from '@core/services';
import {AnimationController, IonicModule, ModalController} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {assetLogo, assetPaymentHorizontal, footerItems} from '@core/local-provider';
import {ThemeLangSettingsComponent} from '@shared/theme-lang-settings/theme-lang-settings.component';
import {ThemeLangSettingsModule} from '@shared/theme-lang-settings/theme-lang-settings.module';

@Component({
  selector: 'app-footer',
  templateUrl: 'fotter.component.html',
  styleUrls: ['fotter.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, ThemeLangSettingsModule]
})
export class FooterComponent {
  @Input() navTrigger: string = 'footerTrigger';
  @Input() payBanner: boolean = true;
  footerItems: {title: string; url: string; icon: string}[] = footerItems;
  isSettingOpen = false;
  assetLogo = assetLogo;
  paymentBannerHorizontal = assetPaymentHorizontal;

  protected facade = inject(FacadeService);
  private modalCtrl = inject(ModalController);
  private animationCtrl = inject(AnimationController);

  async openSettings() {
    this.isSettingOpen = !this.isSettingOpen;
    const modal = await this.modalCtrl.create({
      component: ThemeLangSettingsComponent,
      cssClass: 'settings-modal',
      handle: false,
      breakpoints: [600 / this.facade.ui.deviceHeight, 0.6, 0.9],
      initialBreakpoint: 600 / this.facade.ui.deviceHeight
    });
    this.rotateAnim(true);
    await modal.present();
    modal.onDidDismiss().then((_) => {
      this.rotateAnim(false);
    });
  }

  rotateAnim(isOpening: boolean) {
    const rotation = isOpening ? 'rotate(-180deg)' : 'rotate(180deg)';
    this.animationCtrl
      .create()
      .addElement(document.querySelector('.btn-setting'))
      .delay(isOpening ? 0 : 50)
      .duration(300)
      .fromTo('transform', 'rotate(0deg)', rotation)
      .play();
  }
}
