import {Injectable, inject} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {FacadeService} from '../services';

@Injectable({
  providedIn: 'root'
})
export class NetworkGuard implements CanActivate {
  private facade = inject(FacadeService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const connectionStatus = await this.facade.net.networkStatus();
    return connectionStatus
      ? true
      : this.router.navigate(['/network-error'], {
          queryParams: {redirect: state.url}
        });
  }
}
