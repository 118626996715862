import {Injectable} from '@angular/core';
import {ApiResponse, PremiumModelTest, PremiumModelTestFilter, SubNotifier} from '@core/interfaces';
import {handleError, premiumModelTest} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PremiumModelTestService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll(filter: PremiumModelTestFilter) {
    const params = new HttpParams().set('premiumPlanId', filter.premiumPlanId).set('search', filter.search ?? '');

    return this.http
      .get<ApiResponse<PremiumModelTest[]>>(premiumModelTest, {
        params
      })
      .pipe(catchError(handleError));
  }
}
