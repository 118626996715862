/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {S3} from 'aws-sdk';
import {Spaces} from '../api';
import {FileHandle} from '../interfaces';

@Injectable({providedIn: 'root'})
export class CommonService {
  spaces = new Spaces();
  constructor(private sanitizer: DomSanitizer) {}

  processFiles(fileList: any, extraPath?: string): FileHandle[] {
    const additionalPath = extraPath ? extraPath + '/' : '';
    const files: FileHandle[] = [];
    for (const file of fileList) {
      const dateTime = Date.now();
      const fileName = dateTime + '_' + (Math.floor(Math.random() * 90000) + 10000) + '.' + file.name.split('.').pop();
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      const uploadUrl = this.spaces.endpoint + this.spaces.quizConsole + additionalPath + fileName;
      files.push({
        file,
        fileName,
        url,
        uploadUrl,
        progress: 0,
        uploading: true
      });
    }
    return files;
  }

  uploadToSpace(file: FileHandle, extraPath?: string): any {
    const additionalPath = extraPath ? extraPath + '/' : '';
    const param = {
      Bucket: this.spaces.bucketName,
      Key: this.spaces.quizConsole + additionalPath + file.fileName,
      Body: file.file,
      ACL: 'public-read',
      ContentType: file.file?.type
    };
    return param;
  }

  deleteFileFromSpace(fileURL: string): boolean {
    const params: S3.Types.DeleteObjectRequest = {
      Bucket: this.spaces.bucketName,
      Key: this.spaces.quizConsole + fileURL.split('/')[fileURL.split('/').length - 1]
    };

    this.spaces.s3BUCKET.deleteObject(params, (error: any) => {
      if (error) {
        return false;
      } else {
        return true;
      }
    });

    return true;
  }
}
