import {Injectable, inject} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {I18nService} from '.';
import {
  keyModelTestView,
  keyQuestionBankView,
  keySubjectView,
  keyTopicView,
  keySettingView,
  keyContentView,
  keyPremiumPlanView,
  keyMyPremiumPlanView,
  keyMyPremiumPlanModelTestView,
  keyModelTestLogView,
  keyPremiumModelTestLogView,
  keyElevateCard,
  keyPagination,
  keyTheme,
  keyHideUpcomingFeature,
  keyOnboardingCompleted,
  keyThemeColor,
  keyImageMode
} from '../local-provider';
import {SubNotifier} from '../interfaces';

@Injectable({providedIn: 'root'})
export class SettingsService {
  private i18nService = inject(I18nService);

  notify$ = new BehaviorSubject<any>('');
  hideUpcomingFeature$ = new BehaviorSubject<boolean>(this.hideUpcomingFeature);

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  // LANGUGE
  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get currentTheme(): string {
    return localStorage.getItem(keyTheme) ?? this.themes.dark;
  }

  get currentThemeColor(): string {
    return localStorage.getItem(keyThemeColor) ?? this.themeColors.default;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get paginationView(): boolean {
    const isPaginatedView = localStorage.getItem(keyPagination);
    return isPaginatedView ? JSON.parse(isPaginatedView) : false;
  }

  get themes(): any {
    return {dark: 'dark', light: 'light'};
  }

  get themeColors(): any {
    return {neon: 'neon', default: 'default'};
  }

  get isElevatedCard(): boolean {
    const isElevatedCard = localStorage.getItem(keyElevateCard);
    return isElevatedCard ? JSON.parse(isElevatedCard) : false;
  }

  get isImageMode(): boolean {
    const isImageMode = localStorage.getItem(keyImageMode);
    return isImageMode ? JSON.parse(isImageMode) : true;
  }

  get hideUpcomingFeature(): boolean {
    const hideUpcomingFeature = localStorage.getItem(keyHideUpcomingFeature);
    return hideUpcomingFeature ? JSON.parse(hideUpcomingFeature) : false;
  }

  get isGridView(): boolean {
    const viewType = [
      localStorage.getItem(keySubjectView),
      localStorage.getItem(keyTopicView),
      localStorage.getItem(keyQuestionBankView),
      localStorage.getItem(keyModelTestView),
      localStorage.getItem(keyPremiumPlanView),
      localStorage.getItem(keyMyPremiumPlanView),
      localStorage.getItem(keyMyPremiumPlanModelTestView),
      localStorage.getItem(keyModelTestLogView),
      localStorage.getItem(keyPremiumModelTestLogView),
      localStorage.getItem(keyContentView),
      localStorage.getItem(keySettingView)
    ];
    return viewType.filter((i) => i === 'true').length === 12;
  }

  get isOnboardingCompleted(): boolean {
    const isCompleted = localStorage.getItem(keyOnboardingCompleted);
    return isCompleted ? JSON.parse(isCompleted) : false;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  setTheme(theme: string) {
    localStorage.setItem(keyTheme, theme);
    document.body?.setAttribute('data-theme', theme);
  }

  setPagination(isPaginatedView: boolean) {
    localStorage.setItem(keyPagination, JSON.stringify(isPaginatedView));
    this.notifyOther({refresh: true});
  }

  setIsElevatedCard(isElevatedCard: boolean) {
    localStorage.setItem(keyElevateCard, JSON.stringify(isElevatedCard));
    this.notifyOther({refresh: true});
  }

  setIsImageMode(isImageMode: boolean) {
    localStorage.setItem(keyImageMode, JSON.stringify(isImageMode));
    this.notifyOther({refresh: true});
  }

  sethideUpcomingFeature(isHideFeature: boolean) {
    localStorage.setItem(keyHideUpcomingFeature, JSON.stringify(isHideFeature));
    this.hideUpcomingFeature$.next(isHideFeature);
  }

  setIsOnboardingCompleted(isCompleted: boolean) {
    localStorage.setItem(keyOnboardingCompleted, JSON.stringify(isCompleted));
  }

  setAllGridView(isGridView: boolean) {
    const pages = ['s', 't', 'qb', 'mt', 'pp', 'mpp', 'mppmt', 'mtl', 'pmtl', 'c', 'set'];
    pages.forEach((item: any) => this.setGridView(item, isGridView));
  }

  getIsGridView(type: 's' | 't' | 'qb' | 'mt' | 'pp' | 'mpp' | 'mppmt' | 'mtl' | 'pmtl' | 'c' | 'set'): boolean {
    const viewType = {
      s: localStorage.getItem(keySubjectView),
      t: localStorage.getItem(keyTopicView),
      qb: localStorage.getItem(keyQuestionBankView),
      mt: localStorage.getItem(keyModelTestView),
      pp: localStorage.getItem(keyPremiumPlanView),
      mpp: localStorage.getItem(keyMyPremiumPlanView),
      mppmt: localStorage.getItem(keyMyPremiumPlanModelTestView),
      mtl: localStorage.getItem(keyModelTestLogView),
      pmtl: localStorage.getItem(keyPremiumModelTestLogView),
      c: localStorage.getItem(keyContentView),
      set: localStorage.getItem(keySettingView)
    }[type];
    return viewType !== 'false';
  }

  setGridView(
    type: 's' | 't' | 'qb' | 'mt' | 'pp' | 'mpp' | 'mppmt' | 'mtl' | 'pmtl' | 'b' | 'c' | 'set',
    value: boolean
  ) {
    const component = {
      s: keySubjectView,
      t: keyTopicView,
      qb: keyQuestionBankView,
      mt: keyModelTestView,
      pp: keyPremiumPlanView,
      mpp: keyMyPremiumPlanView,
      mppmt: keyMyPremiumPlanModelTestView,
      mtl: keyModelTestLogView,
      pmtl: keyPremiumModelTestLogView,
      c: keyContentView,
      set: keySettingView
    }[type];

    localStorage.setItem(component, JSON.stringify(value));
    this.notifyOther({refresh: true});
  }
}
