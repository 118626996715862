import {Injectable} from '@angular/core';
import {ApiResponse, Report, ReportPayload, SubNotifier} from '@core/interfaces';
import {handleError, report} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ReportService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll() {
    const params = new HttpParams().set('type', 'question');
    return this.http.get<ApiResponse<Report[]>>(report, {params}).pipe(catchError(handleError));
  }

  reportQuestion(body: ReportPayload) {
    return this.http.post(report, body).pipe(catchError(handleError));
  }

  sendEmail(emailSubject?: string, emailBody?: string) {
    const recipient = 'me@teamtryo.com';
    const subject = emailSubject ?? 'QuizConsole - Error';
    const body = emailBody ?? `[**Please Describe The Problem you are facing]\n\n`;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }
}
