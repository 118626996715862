import {CourseItem, ExtraInfo} from '.';

export enum UserRole {
  learner = 'learner',
  admin = 'admin',
  superAdmin = 'superAdmin',
  tester = 'tester'
}

export enum UserStatus {
  active = 'active',
  inactive = 'inactive',
  banned = 'banned',
  deleted = 'deleted'
}

export interface UserCredentials {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  status: UserStatus;
  points: number;
  password: string;
  profileImage: any;
  mobile: any;
  address: any;
  emailVerification: any;
  mobileVerification: any;
  rememberMe: boolean;
  firebaseId: string;
  oneSignalId: string;
  hashedRt: any;
  accessToken: string;
  refreshToken: string;
  userCoursePreferences: UserCoursePreference[];
  createdAt: string;
  updatedAt: string;
  wishCart: {cart: number; wish: number};
  purchase?: any;
}

export interface UserCoursePreference {
  id: number;
  courseId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  course: CourseItem;
}

export interface AppConfig {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  value: string;
  extraInfo: ExtraInfo & {mandatory: string};
}
