import {Component, ElementRef, ViewChild} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {DirectiveModule} from '@core/directives/directive.module';
import {CommonModule} from '@angular/common';
import {onItemEntry} from '@assets/animations/animations';
import {assetLogo} from '@core/local-provider';
import { NavbarComponent } from '@app/layouts/navbar/navbar.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: 'privacy-policy.page.html',
  styleUrls: ['privacy-policy.page.scss'],
  animations: [onItemEntry],
  standalone: true,
  imports: [CommonModule, IonicModule, DirectiveModule, NavbarComponent]
})
export class PrivacyPolicyPage {
  @ViewChild('pageTop') pageTop: ElementRef;
  @ViewChild('whatData') whatData: ElementRef;
  @ViewChild('howProcessInfo') howProcessInfo: ElementRef;
  @ViewChild('whenAndWhom') whenAndWhom: ElementRef;
  @ViewChild('cookies') cookies: ElementRef;
  @ViewChild('socialLogin') socialLogin: ElementRef;
  @ViewChild('howLong') howLong: ElementRef;
  @ViewChild('howKeepInfo') howKeepInfo: ElementRef;
  @ViewChild('privacyRights') privacyRights: ElementRef;
  @ViewChild('controlTracking') controlTracking: ElementRef;
  @ViewChild('updateNotice') updateNotice: ElementRef;
  @ViewChild('contactNotice') contactNotice: ElementRef;
  @ViewChild('reviewUpdate') reviewUpdate: ElementRef;

  highlightId = '';
  showScrollTop = false;
  assetLogo = assetLogo;

  constructor() {}

  scrollIntoView(elem: string) {
    const ref = this[elem];
    const sectionId = ref.nativeElement.id;
    this.highlightId = sectionId;
    ref.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

    setTimeout(() => {
      this.highlightId = '';
    }, 1500);
  }

  logScrolling(event) {
    this.showScrollTop = event.detail?.scrollTop >= 150;
  }
}
