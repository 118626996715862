import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Network, ConnectionStatus} from '@capacitor/network';

@Injectable({providedIn: 'root'})
export class NetworkService {
  getStatusChangeMsg$: Observable<ConnectionStatus>;
  private status = new BehaviorSubject<ConnectionStatus>(null);

  constructor(private zone: NgZone) {
    Network.addListener('networkStatusChange', (status) => {
      this.zone.run(() => {
        this.status.next(status);
      });
    });

    this.getStatusChangeMsg$ = this.status.asObservable();
  }

  async networkStatus() {
    const status = await Network.getStatus();
    return status.connected;
  }
}
