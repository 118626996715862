import {
  ApiResponse,
  Bookmark,
  BookmarkQuestion,
  BookmarkQuestionFilter,
  BookmarkType,
  Data,
  PaginationParam,
  SubNotifier
} from '@core/interfaces';
import {BehaviorSubject} from 'rxjs';
import {bookmark, handleError, pagination} from '../api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BookmarkService {
  notify$ = new BehaviorSubject<any>('');
  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll(type: BookmarkType) {
    const params = new HttpParams().set('type', type);
    return this.http
      .get<ApiResponse<Bookmark[]>>(bookmark, {
        params
      })
      .pipe(catchError(handleError));
  }

  getAllWithPagination(paginationParam: PaginationParam, filter: BookmarkQuestionFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('type', filter.type)
      .set('search', filter.search ?? '');

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }

    params = filter.orderById ? params.set('orderById', filter.orderById) : params.set('orderById', 'desc');

    return this.http
      .get<ApiResponse<Data<BookmarkQuestion[], BookmarkQuestionFilter>>>(bookmark + pagination, {
        params
      })
      .pipe(catchError(handleError));
  }

  updateBookmark(type: BookmarkType, questionId: number) {
    return this.http
      .post(bookmark, {
        type,
        questionId
      })
      .pipe(catchError(handleError));
  }
}
