import {Component, EventEmitter, Output, inject} from '@angular/core';
import {assetNotFound} from '@core/local-provider';
import {onItemEntry} from '@assets/animations/animations';
import {FacadeService} from '@core/services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [onItemEntry]
})
export class NotFoundComponent {
  @Output() clickEvent = new EventEmitter();
  facade = inject(FacadeService);
  assetNotFound = assetNotFound;
}
