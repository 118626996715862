import {CommonModule} from '@angular/common';
import {Component, Input, inject} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ThemeLangSettingsModule} from '@shared/theme-lang-settings/theme-lang-settings.module';
import {assetLogo, footerItems} from '@core/local-provider';
import {FacadeService} from '@core/services';
import {AnimationController, IonicModule, ModalController} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ThemeLangSettingsComponent} from '@shared/theme-lang-settings/theme-lang-settings.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, RouterModule, ThemeLangSettingsModule]
})
export class NavbarComponent {
  @Input() navTrigger: string = 'navTrigger';
  protected facade = inject(FacadeService);
  private modalCtrl = inject(ModalController);
  private animationCtrl = inject(AnimationController);

  scrollData$: Subscription;
  pageScrolled: boolean = false;
  assetLogo = assetLogo;
  footerItems: {title: string; url: string; icon: string}[] = footerItems;
  isSettingOpen = false;

  async openSettings() {
    this.isSettingOpen = !this.isSettingOpen;
    const modal = await this.modalCtrl.create({
      component: ThemeLangSettingsComponent,
      cssClass: 'settings-modal',
      handle: false,
      breakpoints: [600 / this.facade.ui.deviceHeight, 0.6, 0.9],
      initialBreakpoint: 600 / this.facade.ui.deviceHeight
    });
    this.rotateAnim(true);
    await modal.present();
    modal.onDidDismiss().then((_) => {
      this.rotateAnim(false);
    });
  }

  rotateAnim(isOpening: boolean) {
    const rotation = isOpening ? 'rotate(-180deg)' : 'rotate(180deg)';
    this.animationCtrl
      .create()
      .addElement(document.querySelector('.btn-setting'))
      .delay(isOpening ? 0 : 50)
      .duration(300)
      .fromTo('transform', 'rotate(0deg)', rotation)
      .play();
  }
}
