import {Injectable, inject} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn} from '@angular/router';
import {FacadeService} from '@core/services';
import {JwtHelperService} from '@auth0/angular-jwt';
import {keySignedInOnce} from '../local-provider';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  facade = inject(FacadeService);
  router = inject(Router);
  retry = 0;
  public jwtHelper: JwtHelperService = new JwtHelperService();

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.facade.auth.isAuthenticated()) {
      const user = this.facade.auth.getCredential;
      if (!this.jwtHelper.isTokenExpired(user.accessToken)) {
        // check selected preferences
        if (!user.userCoursePreferences || user.userCoursePreferences?.length < 1) {
          this.router.navigate(['course-preference'], {
            queryParams: {redirect: state.url},
            replaceUrl: true
          });
          return false;
        }
        return true;
      } else {
        const isRefreshSuccess = await this.refreshingTokens();
        if (!isRefreshSuccess) {
          this.router.navigate(['auth'], {
            queryParams: {redirect: state.url},
            replaceUrl: true
          });
          return false;
        }
        return true;
      }
    } else {
      const routeUrl = localStorage.getItem(keySignedInOnce) ? 'auth' : 'landing';
      this.router.navigate([routeUrl], {
        queryParams: {redirect: state.url},
        replaceUrl: true
      });
      return false;
    }
  }

  private async refreshingTokens() {
    try {
      const result = await this.facade.auth.refreshToken().toPromise();
      if (result.status === 'success') {
        this.facade.auth.setUserCredential(
          {
            ...this.facade.auth.getCredential,
            accessToken: result.data.accessToken,
            refreshToken: result.data.refreshToken
          },
          this.facade.auth.getCredential?.rememberMe
        );
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(PermissionsService).canActivate(next, state);
};
