import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParameterTypeCheckGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.data.lastParam) {
      const id = +route.paramMap.get(route.data.lastParam);
      if (isNaN(id)) {
        this.router.navigate(['**']);
        return false;
      }
    }
    return true;
  }
}
