import {allThemes} from '@core/local-provider';
import {Component, inject} from '@angular/core';
import {Language} from '@core/interfaces';
import {supportedLanguages} from '@core/local-provider';
import {FacadeService} from '@core/services';
import {ModalController} from '@ionic/angular';
import {tap} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-theme-lang-settings',
  templateUrl: './theme-lang-settings.component.html',
  styleUrls: ['./theme-lang-settings.component.scss']
})
export class ThemeLangSettingsComponent {
  protected facade = inject(FacadeService);
  protected modalCtrl = inject(ModalController);

  currentLanguage;
  isDarkTheme = false;
  allLanguages: Language[] = supportedLanguages;
  allThemes: string[] = allThemes;
  isGridView = this.facade.setting.getIsGridView('set');

  constructor() {
    this.currentLanguage = this.facade.setting.currentLanguage;
    this.isDarkTheme = this.facade.setting.currentTheme === this.facade.setting.themes.dark;

    this.facade.setting.notify$
      .pipe(
        tap(() => (this.isGridView = this.facade.setting.getIsGridView('set'))),
        untilDestroyed(this)
      )
      .subscribe();
  }

  setTheme(theme: string) {
    this.isDarkTheme = theme === this.facade.setting.themes.dark;
    this.facade.setting.setTheme(theme);
  }
  setLanguage(language: string) {
    this.facade.setting.setLanguage(language);
  }

  setViewtype(value: boolean) {
    this.isGridView = value;
    this.facade.setting.setGridView('set', this.isGridView);
  }
}
