import {Injectable} from '@angular/core';
import {
  ApiResponse,
  AutoGenExam,
  Data,
  ModelTestLog,
  ModelTestLogFilter,
  PaginationParam,
  PremiumAutoTestLogFilter,
  PremiumGeneratedModelTestLog,
  PremiumTestLog,
  PremiumTestLogFilter,
  SubNotifier
} from '@core/interfaces';
import {
  handleError,
  modelTestLog,
  pagination,
  premiumAutoTestLog,
  premiumModelTestLog,
  premiumPlanAutoGenExamLogReview
} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TestLogService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getSingleMTLog(id: number) {
    return this.http.get<ApiResponse<ModelTestLog>>(modelTestLog + `/${id}`).pipe(catchError(handleError));
  }

  getSinglePMTLog(id: number) {
    return this.http.get<ApiResponse<PremiumTestLog>>(premiumModelTestLog + `/${id}`).pipe(catchError(handleError));
  }

  getSinglePMATLog(id: number) {
    return this.http
      .get<ApiResponse<AutoGenExam>>(premiumPlanAutoGenExamLogReview + `/${id}`)
      .pipe(catchError(handleError));
  }

  getAllMTLogWithPagination(paginationParam: PaginationParam, filter: ModelTestLogFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('search', filter.search ?? '');

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }

    if (filter.orderById) {
      params = params.set('orderById', filter.orderById);
    }

    if (filter.orderByScore) {
      params = params.set('orderByScore', filter.orderByScore);
    }

    return this.http
      .get<ApiResponse<Data<ModelTestLog[], ModelTestLogFilter>>>(modelTestLog + pagination, {
        params
      })
      .pipe(catchError(handleError));
  }

  getAllPMTLogWithPagination(paginationParam: PaginationParam, filter: PremiumTestLogFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('search', filter.search ?? '');

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }

    if (filter.orderById) {
      params = params.set('orderById', filter.orderById);
    }

    if (filter.orderByScore) {
      params = params.set('orderByScore', filter.orderByScore);
    }

    return this.http
      .get<ApiResponse<Data<PremiumTestLog[], PremiumTestLogFilter>>>(premiumModelTestLog + pagination, {params})
      .pipe(catchError(handleError));
  }

  getAllPATLogWithPagination(paginationParam: PaginationParam, filter: PremiumAutoTestLogFilter) {
    let params = new HttpParams().set('page', paginationParam.page).set('limit', paginationParam.limit);

    if (filter.courseIds) {
      params = params.set('courseIds', JSON.stringify(filter.courseIds));
    }

    if (filter.orderById) {
      params = params.set('orderById', filter.orderById);
    }

    if (filter.orderByScore) {
      params = params.set('orderByScore', filter.orderByScore);
    }

    return this.http
      .get<ApiResponse<Data<PremiumGeneratedModelTestLog[], PremiumAutoTestLogFilter>>>(
        premiumAutoTestLog + pagination,
        {params}
      )
      .pipe(catchError(handleError));
  }
}
