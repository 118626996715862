import * as AWS from 'aws-sdk';
import {environment} from '@env/environment';

export class Spaces {
  s3BUCKET = new AWS.S3({
    ...environment.doSpaces,
    httpOptions: {
      timeout: 1000 * 60 * 1 // 1 minutes
    }
  });

  s3BucketVideo = new AWS.S3({
    ...environment.doSpaces,
    httpOptions: {
      timeout: 1000 * 60 * 30 // 30 minutes
    }
  });

  endpoint = environment.doSpacesUploadEndpoint;
  bucketName = environment.doSpacebucketName;
  quizConsole = `quiz-console/`;
}
