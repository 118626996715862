export interface Content {
  id: number;
  title: string;
  contentType: ContentType;
  content: 'string';
  status: 'published';
  categories: ['string'];
  createdAt: '2022-08-27T15:15:59.511Z';
  updatedAt: '2022-08-27T15:15:59.511Z';
  icon?: string;
}

export enum ContentType {
  article = 'article',
  media = 'media',
  image = 'image',
  document = 'document'
}

export interface ContentFilter {
  search: string;
  orderById: string | null;
  status: string;
  contentType: ContentType[];
  courseIds?: number[];
}
