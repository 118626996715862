import {Injectable} from '@angular/core';
import {purchaseLog, pagination, handleError} from '@core/api';
import {ApiResponse, Data, PaginationParam, PurchaseLogFilter, PurchaseLogItem, SubNotifier} from '@core/interfaces';

import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class PurchaseLogService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAllWithPagination(paginationParam: PaginationParam, filter: PurchaseLogFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page ?? 1)
      .set('limit', paginationParam.limit ?? 20)
      .set('search', filter.search ?? '');

    params = filter.orderById ? params.set('orderById', filter.orderById) : params.set('orderById', 'desc');

    return this.http
      .get<ApiResponse<Data<PurchaseLogItem[], PurchaseLogFilter>>>(purchaseLog + pagination, {params})
      .pipe(catchError(handleError));
  }
}
