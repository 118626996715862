export const keySignedInOnce = 'signedInOnce';

// USER
export const keyUserCredentials = 'userCredentials';
export const keyResetPasswordEmail = 'resetPasswordEmail';

// VIEW
export const keySubjectView = 'sVT';
export const keyTopicView = 'tVT';
export const keyQuestionBankView = 'qbVT';
export const keyModelTestView = 'mtVT';
export const keyPremiumPlanView = 'ppVT';
export const keyMyPremiumPlanView = 'mppVT';
export const keyMyPremiumPlanModelTestView = 'mppmtVT';
export const keyModelTestLogView = 'mtlVT';
export const keyPremiumModelTestLogView = 'pmtlVT';
export const keyContentView = 'cVT';
export const keySettingView = 'sVT';

// ORDER
export const keySubjectOrderByTitle = 'sOT';
export const keyTopicOrderByTitle = 'tOT';
export const keyQuestionBankOrderByTitle = 'qbOT';
export const keyModelTestOrderByTitle = 'mtOT';
export const keyMyPremiumPlanOrderByTitle = 'mppOT';
export const keyPremiumPlanModelTestOrderByTitle = 'mppmtOT';

export const keyModelTestLogOrderByDate = 'mtlOD';
export const keyPurchaseLogOrderByDate = 'plOD';
export const keyPremiumModelTestLogOrderByDate = 'pmtlOD';
export const keyBookmarkOrderByDate = 'bOD';
export const keyContentOrderByDate = 'cOD';

export const keyModelTestLogOrderByScore = 'mtlOS';
export const keyPremiumModelTestLogOrderByScore = 'pmtlOS';
export const keyPremiumPlanOrderByPrice = 'ppOP';


// AUTO PREMIUM EXAM
export const keyExamRunning = 'autoGenExam';
export const keyExamWholeData = 'autoGenExamWholeData';

// SETTINGS
export const keyLanguage = 'language';
export const keyTheme = 'theme';
export const keyThemeColor = 'themeColor';
export const keyPagination = 'pagination';
export const keyElevateCard = 'cardElevation';
export const keyImageMode = 'cardImageMode';

// DASHBOARD
export const keyHideUpcomingFeature = 'hideUpcomingFeature';
export const keyOnboardingCompleted = 'onboardingCompleted';
