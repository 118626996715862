import {Injectable} from '@angular/core';
import {
  ApiResponse,
  Data,
  PaginationParam,
  SubjectQuestionFilter,
  SubjectQuestion,
  SubNotifier
} from '@core/interfaces';
import {handleError, subjectQuestion, pagination} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SubjectQuestionService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAllWithPagination(paginationParam: PaginationParam, filter: SubjectQuestionFilter) {
    let params = new HttpParams()
      .set('page', paginationParam.page)
      .set('limit', paginationParam.limit)
      .set('topicId', filter.topicId)
      .set('search', filter.search ?? '');

    if (filter.subjectId) {
      params = params.set('subjectId', filter.subjectId);
    }

    return this.http
      .get<ApiResponse<Data<SubjectQuestion[], SubjectQuestionFilter>>>(subjectQuestion + pagination, {
        params
      })
      .pipe(catchError(handleError));
  }
}
