export interface DashboardItem {
  title: string;
  link?: string;
  itemAnalytic?: ItemAnalytic;
  questAnalytic?: ItemAnalytic;
  extraInfo?: ExtraInfo;
}

export interface DashboardAPIResponse {
  subjectItems: number;
  subjectQuestions: number;
  questionBankItems: number;
  questionBankQuestions: number;
  modelTestItems: number;
  modelTestQuestions: number;
  premiumPlanItems: number;
  premiumMTItems: number;
  premiumMTQuestions: number;
  bookmarkItems: number | {questionIds: number[]}[];
}

export interface ItemAnalytic {
  title?: string;
  value?: string;
  icon?: string;
  color?: string;
}

export interface ExtraInfo {
  info?: string;
  icon?: string;
  image?: string;
  color?: string;
  backgroundColor?: string;
  images?: string[];
  tags?: string[];
  topics?: string[];
  discount?: number;
  freeAttempt?: number
}

export interface UserAnalytic {
  practicedQuestion?: number;
}
