import {DashboardItem} from '@core/interfaces';
import {iconBookmark, iconContent, iconModelTest, iconQb, iconSubject, iconTestLog} from './assets.provider';

export const contentsAndTestLogItems: DashboardItem[] = [
  {
    title: 'content.title',
    link: '/contents',
    extraInfo: {
      info: 'content.info',
      icon: 'document-text-outline',
      image: iconContent
    }
  },
  {
    title: 'testLog.title',
    link: '/test-logs',
    extraInfo: {
      info: 'testLog.info',
      icon: 'analytics',
      image: iconTestLog
    }
  }
];

export const premuimTestItem: DashboardItem = {
  title: 'dashboard.premiumPlanTitle',
  link: '/premium-plans',
  extraInfo: {
    info: 'dashboard.premiumPlanDesc',
    image: '',
    icon: 'ribbon-outline',
    backgroundColor: 'primary',
    color: 'white'
  }
};

export const myPremuimTestItem: DashboardItem = {
  title: 'dashboard.myPremiumPlanTitle',
  link: '/my-premium-plans',
  extraInfo: {
    info: 'dashboard.myPremiumPlanDesc',
    image: '',
    icon: 'cube-outline',
    backgroundColor: 'secondary',
    color: 'white'
  }
};

export const leaderBoardItem: DashboardItem = {
  title: 'dashboard.leaderboardTitle',
  link: '/leader-board',
  extraInfo: {
    info: 'dashboard.leaderboardDesc',
    image: '',
    icon: 'people-outline',
    backgroundColor: 'tertiary',
    color: 'white'
  }
};

export const educationItems: DashboardItem[] = [
  {
    title: 'subject.title',
    link: '/subjects',
    itemAnalytic: {
      title: 'common.items',
      value: null
    },
    questAnalytic: {
      title: 'common.questions',
      value: null
    },
    // extraInfo: {info: 'subject.info', icon: iconSubject }
    extraInfo: {info: 'subject.info', icon: 'albums-outline', image: iconSubject}
  },
  {
    title: 'questionBank.title',
    link: '/question-bank',
    itemAnalytic: {
      title: 'common.items',
      value: null
    },
    questAnalytic: {
      title: 'common.questions',
      value: null
    },
    // extraInfo: {info: 'questionBank.info', icon: iconQb}
    extraInfo: {info: 'questionBank.info', icon: 'layers-outline', image: iconQb}
  },
  {
    title: 'modelTest.title',
    link: '/model-tests',
    itemAnalytic: {
      title: 'common.tests',
      value: null
    },
    questAnalytic: {
      title: 'common.questions',
      value: null
    },
    // extraInfo: {info: 'modelTest.info', icon: iconModelTest}
    extraInfo: {info: 'modelTest.info', icon: 'alarm-outline', image: iconModelTest}
  },
  {
    title: 'bookmark.title',
    link: '/bookmarks',
    questAnalytic: {
      title: 'bookmark.bookmarked',
      value: null
    },
    // extraInfo: {info: 'bookmark.info', icon: iconBookmark}
    extraInfo: {info: 'bookmark.info', icon: 'bookmark-outline', image: iconBookmark}
  }
];
