import {Injectable} from '@angular/core';
import {ApiResponse, LeaderBoard, LeaderBoardFilter, PremiumPlanPurchaseLog, SubNotifier} from '@core/interfaces';
import {handleError, leaderBoardMyPMT, leaderBoardPMTByDate} from '@core/api';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LeaderBoardService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getMyPremiumModelTest() {
    return this.http.get<ApiResponse<PremiumPlanPurchaseLog[]>>(leaderBoardMyPMT).pipe(catchError(handleError));
  }

  getPremiumLeaderBoardByPMTDate(filter: LeaderBoardFilter) {
    const params = new HttpParams().set('premiumModelTestId', filter.premiumModelTestId).set('date', filter.date);

    return this.http
      .get<ApiResponse<LeaderBoard[]>>(leaderBoardPMTByDate, {
        params
      })
      .pipe(catchError(handleError));
  }
}
