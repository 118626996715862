import {Injectable, inject} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {catchError, delay, mergeMap, retryWhen, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FacadeService} from '@core/services';
import {JwtHelperService} from '@auth0/angular-jwt';
/**
 * Adds a default error handler to all requests.
 */
@Injectable({providedIn: 'root'})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private facade = inject(FacadeService);
  private router = inject(Router);

  public jwtHelper: JwtHelperService = new JwtHelperService();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401 && !request.url.includes('/refresh')) {
          const user = this.facade.auth.getCredential;
          const tokenExpired = this.jwtHelper.isTokenExpired(user.accessToken);
          if (user.refreshToken && tokenExpired) {
            return from(this.facade.auth.refreshToken()).pipe(
              mergeMap((data: any) => {
                // Retry the failed request with the new access token
                this.facade.auth.setUserCredential(
                  {
                    ...this.facade.auth.getCredential,
                    accessToken: data.data.accessToken,
                    refreshToken: data.data.refreshToken
                  },
                  this.facade.auth.getCredential?.rememberMe
                );
                const authRequest = request.clone();
                return next.handle(authRequest);
              }),
              retryWhen((errors) => errors.pipe(delay(1000), take(3)))
            );
          } else {
            this.facade.auth.logOutSetCredential();
            this.router.navigate(['auth']);
          }
        }
        throw error;
      })
    );
  }
}

export const handleError = (error: HttpErrorResponse | any) => {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.

    if (error.status === 0) {
      const data = error.error;
      data.status = error.status;
      data.statusCode = error.status;
      data.message = [
        {
          message: 'Unable to connect to the server',
          messageKey: 'unableToReachServer'
        }
      ];
      throw data;
    }
  }
  // Return an observable with a user-facing error message.
  throw error.error;
};

// 0 - could not reach server, 400 - bad request, 401 - Unauthorized, 403 -	Forbidden 404 - Not found, 500 - internal server error, 501 - not implemented, 504 - Gateway timeout
// 400	Bad Request
// 401	Unauthorized
// 402	Payment Required
// 403	Forbidden
// 404	Not Found
// 405	Method Not Allowed

// 500	Internal Server Error
// 501	Not Implemented
// 502	Bad Gateway
// 503	Service Unavailable
// 504	Gateway Timeout
