export interface PremiumModelTestSubmissionItem {
  premiumModelTestId: number;
  answers: QuestionAnswer[] | null;
  startDateTime: Date;
  endDateTime: Date;
}

export interface PremiumModelTestResultLog {
  id: number;
  userId: number;
  premiumModelTestId: number;
  score: number;
  correctAnswerCount: number;
  incorrectAnswerCount: number;
  unAnsweredCount: number;
  answers: QuestionAnswer[];
  startDateTime: Date;
  endDateTime: Date;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface QuestionAnswer {
  answer: string;
  id: number;
}
