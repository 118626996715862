import {Directive, ElementRef, inject} from '@angular/core';
import {FacadeService} from '../services';
@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
  private facade = inject(FacadeService);
  private el = inject(ElementRef);
  constructor() {
    const stylesheet = `
      ::-webkit-scrollbar {
        width: 8px;
        margin: 3px;
      }
      ::-webkit-scrollbar-track {        
        margin: 20px;
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background:  var(--ion-color-light-shade);
        border: 1px solid var(--ion-color-light-tint);
      }
      ::-webkit-scrollbar-thumb:hover {
        background:  var(--ion-color-secondary);
      }

      @media only screen and (max-width: 768px) {
        ::-webkit-scrollbar {
          width: 4px;
          margin: 2px;
        }
        
      }
    `;
    const styleElmt = this.el.nativeElement.shadowRoot.querySelector('style');
    if (!this.facade.ui.isMobile) {
      if (styleElmt) {
        styleElmt.append(stylesheet);
      } else {
        const barStyle = document.createElement('style');
        barStyle.append(stylesheet);
        this.el.nativeElement.shadowRoot.appendChild(barStyle);
      }
    }
  }
}
