import {ValueTitle} from '@core/interfaces';

export const questionFilter: ValueTitle[] = [
  {
    value: 0,
    title: 'modelTestExam.sortByAllQuestions'
  },
  {
    value: 1,
    title: 'modelTestExam.sortByUnanswered'
  },
  {
    value: 2,
    title: 'modelTestExam.sortByAnswered'
  }
];
