import {trigger, style, transition, animate} from '@angular/animations';

export const onItemEntry = trigger('onItemEntry', [
  transition(':enter', [
    style({transform: 'scale(0.0)', opacity: 0}), // initial
    animate('0.2s ease-in', style({transform: 'scale(1)', opacity: 1})) // final
  ]),
  transition(':leave', [
    style({transform: 'scale(1)', opacity: 1}),
    animate(
      '0.2s ease-in',
      style({
        transform: 'scale(0.0)',
        opacity: 0
      })
    )
  ])
]);

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({transform: 'translateY(50%)', opacity: 0.0}),
    animate('300ms ease-in', style({transform: 'translateY(0%)', opacity: 1.0}))
  ]),
  transition(':leave', [animate('50ms ease-out', style({transform: 'translateY(50%)', opacity: 0.0}))])
]);

export const slideOutIn = trigger('slideOutIn', [
  transition(':enter', [
    style({transform: 'translateY(-50%)', opacity: 0.0}),
    animate('100ms ease-in', style({transform: 'translateY(0%)', opacity: 1.0}))
  ]),
  transition(':leave', [animate('50ms ease-out', style({transform: 'translateY(-50%)', opacity: 0.0}))])
]);

export const scaleAndCorner = trigger('scaleAndCorner', [
  transition(':leave', [
    animate(
      '0.2s ease-out',
      style({
        transform: 'translate(48%, -50%) scale(0.3)',
        borderRadius: '50%'
      })
    )
  ])
]);

export const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    animate(
      '0.5s ease-out',
      style({
        opacity: 0
      })
    )
  ])
]);

// route animations

export const slideOutInRoute = trigger('slideOutInRoute', [
  transition('*<=>*', [
    // css styles at start of transition
    style({opacity: 0, transform: 'translateY(10%)'}),

    // animation and styles at end of transition
    animate('0.3s', style({opacity: 1, transform: 'translateY(0%)'}))
  ])
]);

export const slideLeftRightRoute = trigger('slideLeftRightRoute', [
  transition('*<=>*', [
    // css styles at start of transition
    style({opacity: 0, transform: 'translateX(10%)'}),

    // animation and styles at end of transition
    animate('0.3s', style({opacity: 1, transform: 'translateX(0%)'}))
  ])
]);

export const slideInUp = trigger('slideInUp', [
  transition(':enter', [
    style({transform: 'translateY(50px)', opacity: 0.0}),
    animate('50ms ease-in', style({transform: 'translateY(0px)', opacity: 1.0}))
  ]),
  transition(':leave', [animate('300ms ease-out', style({transform: 'translateY(50px)', opacity: 0.0}))])
]);
