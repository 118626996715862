export interface ModelTestLog {
  id: number;
  modelTestId: number;
  score: number;
  modelTest?: {id: number; title?: string};
  answers: {id: number; answer?: string}[];
  correctAnswerCount: number;
  incorrectAnswerCount: number;
  unAnsweredCount: number;
  startDateTime: string;
  endDateTime: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ModelTestLogFilter {
  search: string;
  orderById: string;
  orderByScore: string;
  courseIds?: number[];
}

export interface PremiumTestLog {
  id: number;
  premiumModelTestId: number;
  score: number;
  premiumModelTest?: {id: number; title?: string};
  answers: {id: number; answer?: string}[];
  correctAnswerCount: number;
  incorrectAnswerCount: number;
  unAnsweredCount: number;
  startDateTime: string;
  endDateTime: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface PremiumTestLogFilter {
  search: string;
  orderById: string;
  orderByScore: string;
  courseIds?: number[];
}

export interface PremiumAutoTestLogFilter {
  orderById: string;
  orderByScore: string;
  courseIds?: number[];
}