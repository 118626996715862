import {PremiumPlanPurchaseLog} from '.';

export interface PurchaseLogItem {
  id: number;
  userId: number;
  paymentAmount: number;
  tranId: string;
  createdAt: Date;
  updatedAt?: Date;
  premiumPlanPurchaseLogs: Pick<
    PremiumPlanPurchaseLog,
    'id' | 'validUntil' | 'status' | 'extraInfo' | 'premiumPlan' | 'createdAt' | 'updatedAt'
  >[];
}

export interface PurchaseLogFilter {
  search: string;
  orderById: string | null;
}
