import {Injectable, inject} from '@angular/core';
import {
  AnalyticService,
  AuthService,
  BookmarkService,
  CommonService,
  ContentService,
  CourseService,
  I18nService,
  NetworkService,
  OneSignalService,
  SettingsService,
  TestLogService,
  UIService
} from '.';

@Injectable({providedIn: 'root'})
export class FacadeService {
  readonly auth = inject(AuthService);
  readonly analytics = inject(AnalyticService);
  readonly bookmark = inject(BookmarkService);
  readonly common = inject(CommonService);
  readonly content = inject(ContentService);
  readonly course = inject(CourseService);
  readonly lang = inject(I18nService);
  readonly net = inject(NetworkService);
  readonly oneSignal = inject(OneSignalService);
  readonly setting = inject(SettingsService);
  readonly ui = inject(UIService);
  readonly testLog = inject(TestLogService);
}
