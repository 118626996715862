import {Component, ElementRef, ViewChild} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {DirectiveModule} from '@core/directives/directive.module';
import {assetLogo} from '@core/local-provider';
import {onItemEntry} from '@assets/animations/animations';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from '@app/layouts/navbar/navbar.component';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: 'terms-and-conditions.page.html',
  styleUrls: ['terms-and-conditions.page.scss'],
  animations: [onItemEntry],
  standalone: true,
  imports: [CommonModule, IonicModule, DirectiveModule, NavbarComponent]
})
export class TermsAndConditionsPage {
  @ViewChild('pageTop') pageTop: ElementRef;
  showScrollTop = false;
  assetLogo = assetLogo;
  constructor() {}

  scrollIntoView(elem: string) {
    const ref = this[elem];
    ref.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  logScrolling(event) {
    this.showScrollTop = event.detail?.scrollTop >= 150;
  }
}
