// auth
export const appConfig = 'auth/app-config';
export const signIn = 'auth/local/signin';
export const signupinFirebase = 'auth/firebase/signupin';
export const signUp = 'auth/local/signup';
export const verifyEmail = 'auth/local/verify-account';
export const forgotPassword = 'auth/local/forgot-password';
export const forgotResetPassword = 'auth/local/forgot-reset-password';
export const logout = 'auth/logout';
export const refresh = 'auth/refresh';
export const apiGetUser = 'auth/user-data';
export const sendEmailVarification = 'auth/local/send-email-verification';

export const pagination = '/pagination';

// dashboard
export const dashboard = 'learner/dashboard';
export const resources = 'learner/dashboard/get-resources';

// subject
export const subject = 'learner/subject';

// report
export const report = 'learner/report';

// content
export const content = 'learner/content';
// topic
export const topic = 'learner/subject-topic';
// subject question
export const subjectQuestion = 'learner/subject-question';
// questionbank
export const questionBank = 'learner/question-bank';
// questionbank question
export const questionBankQuestion = 'learner/question-bank-question';
// modeltest
export const modelTest = 'learner/model-test';
// modeltest question
export const modelTestQuestion = 'learner/model-test-question';

//modeltest log
export const modelTestLog = 'learner/model-test-log';

// course
export const course = 'auth/course';
export const updateCoursePreference = 'auth/preference';

// premium plans
export const premiumPlan = 'learner/premium-plan';
export const premiumPlanMyPlanList = 'learner/premium-plan/my-plan-list';
export const premiumModelTest = 'learner/premium-model-test';
export const premiumModelTestQuestion = 'learner/premium-model-test-question';
export const premiumModelTestLog = 'learner/premium-model-test-log';

export const premiumPlanAutoPlanList = 'learner/auto-premium-plan/auto-plan-list';
export const premiumPlanAutoSelectList = 'learner/auto-premium-plan/auto-plan-select-list';
export const premiumPlanAutoGenExam = 'learner/auto-premium-plan/generate-exam';
export const premiumPlanAutoGenExamLog = 'learner/auto-premium-plan/submit-exam';
export const premiumPlanAutoGenExamLogReview = 'learner/premium-model-test-log/auto-review';
export const premiumAutoTestLog = 'learner/premium-model-test-log/auto';

export const premiumPlanMyWishCartList = 'learner/premium-plan/my-wish-cart-list';
export const addToCartOrWishList = 'learner/premium-plan/add-to-cart-or-wish-list';
export const updateCartList = 'learner/premium-plan/update-cart-item';
export const removeFromCartOrWishList = 'learner/premium-plan/delete-from-cart-or-wish-list';
export const buyPremiumPlan = 'learner/premium-plan-cart/buy-premium-plan';
export const premiumPlanReviews = 'learner/premium-plan/reviews';

// Leader Board
export const leaderBoardMyPMT = 'learner/leader-board/my-premium-model-test';
export const leaderBoardPMTByDate = 'learner/leader-board/premium-leader-board-by-mt-date';

// bookmark
export const bookmark = 'learner/bookmark';

// purchase-log
export const purchaseLog = 'learner/purchase-log';

// settings
export const resetPassword = 'learner/settings/reset-password';
export const updateUserPicture = 'learner/settings/update-profile-pic';
export const deactivateAccount = 'learner/settings/deactivate-account';
