import {
  ApiResponse,
  ModelTestQuestion,
  ModelTestQuestionFilter,
  ModelTestSubmissionItem,
  QuestAnswer,
  SubNotifier
} from '@core/interfaces';
import {BehaviorSubject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {handleError, modelTestLog, modelTestQuestion} from '@core/api';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class ModelTestQuestionService {
  notify$ = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {}

  notifyOther(data: SubNotifier) {
    if (data) {
      this.notify$.next(data);
    }
  }

  getAll(filter: ModelTestQuestionFilter) {
    let params = new HttpParams().set('modelTestId', filter.modelTestId);

    return this.http
      .get<ApiResponse<ModelTestQuestion[]>>(modelTestQuestion, {
        params
      })
      .pipe(catchError(handleError));
  }

  submitModelTest(param: ModelTestSubmissionItem) {
    return this.http.post(modelTestLog, param).pipe(catchError(handleError));
  }

  // time
  getTempAnswer(modelTestId: number): QuestAnswer[] {
    const key = modelTestId + '_temp_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setTempAnswer(modelTestId: number, answer: QuestAnswer[]): void {
    const key = modelTestId + '_temp_answer';
    localStorage.setItem(key, JSON.stringify(answer));
  }

  removeTempAnswers(modelTestId: number) {
    const key = modelTestId + '_temp_answer';
    localStorage.removeItem(key);
  }

  // temp answer
  getSubmissionItem(modelTestId: number): ModelTestSubmissionItem {
    const key = modelTestId + '_answer';
    return JSON.parse(localStorage.getItem(key));
  }

  setSubmissionItem(submissionItem: ModelTestSubmissionItem): void {
    const key = submissionItem.modelTestId + '_answer';
    localStorage.setItem(key, JSON.stringify(submissionItem));
  }

  removeSubmissionItem(modelTestId: number): void {
    const key = modelTestId + '_answer';
    localStorage.removeItem(key);
  }

  // timer
  getTimeCounter(modelTestId: number) {
    const key = modelTestId + '_timer';

    return JSON.parse(localStorage.getItem(key));
  }

  setTimeCounter(modelTestId: number, counter) {
    const key = modelTestId + '_timer';
    localStorage.setItem(key, JSON.stringify(counter));
    return JSON.parse(localStorage.getItem(key));
  }

  removeTimeCounter(modelTestId: number) {
    const key = modelTestId + '_timer';
    localStorage.removeItem(key);
  }
}
